import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import userManagementIconActive from '../../assets/images/User Management.svg';
import userManagementIcon from '../../assets/images/User Management (White).svg';
import hierarchy_icon from "../../assets/images/hierarchy_icon.png";
import hierarchy_white_icon from "../../assets/images/hierarchy_white_icon.png";
import event_icon from "../../assets/images/event_icon.png";
import event_white_icon from "../../assets/images/event_white_icon.png";
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import './index.css'; // Import CSS for styling

interface TabProps {
    title: string;
    icon: string;
    isActiveIcon: string;
    path: string;
}

const Tab: React.FC<TabProps & { isActive: boolean; onClick: () => void; }> = ({ title, icon, isActive, isActiveIcon, onClick }) => {
    return (
        <button className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
            {isActive ?
                <img src={isActiveIcon} alt={title} className="icon" />
                :
                <img src={icon} alt={title} className="icon" />
            }
            <span className="title">{title}</span>
        </button>
    );
};

const Tabs: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { userData } = useSelector((state: RootState) => state.auth);

    const handleTabClick = (path: string) => {
        if (location.pathname !== path) {
            navigate(path);
        }
    };
    const substrings = ["/login", "/form"];

    const containsSubstring = substrings.some(substring => substring === location.pathname);
    if (containsSubstring) {
        return null;
    }

    return (
        <div className="tabs">
            <Tab title="User Management" icon={userManagementIconActive} isActiveIcon={userManagementIcon} path="/users" isActive={location.pathname === '/users'} onClick={() => handleTabClick('/users')} />
            {userData.isSubperAdmin ? <Tab title="Hierarchy" icon={hierarchy_icon} isActiveIcon={hierarchy_white_icon} path="/hierarchy" isActive={location.pathname === '/hierarchy'} onClick={() => handleTabClick('/hierarchy')} /> : null}
            {userData.isSubperAdmin ? <Tab title="Events" icon={event_icon} isActiveIcon={event_white_icon} path="/events" isActive={location.pathname === '/events'} onClick={() => handleTabClick('/events')} /> : null}
            {userData.isSubperAdmin ? <Tab title="Import" icon={hierarchy_icon} isActiveIcon={hierarchy_white_icon} path="/import" isActive={location.pathname === '/import'} onClick={() => handleTabClick('/import')} /> : null}
        </div>
    );
};

export default Tabs;
