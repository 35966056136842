import { gql } from "@apollo/client";

export const getDivision = gql`
query getAllDivisions {
  divisions(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        division_name
      }
    }
  }
}
`;

export const getDivisionById = gql`
query getDivisionById($id: ID) {
  division(id: $id) {
    data {
      id
      attributes {
        division_name
      }
    }
  }
}
`;

export const getDistrict = gql`
query getAllDistrict($filter: DistrictFiltersInput) {
  districts(filters: $filter, pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        district_name
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
      }
    }
  }
}
`;

export const getDistrictById = gql`
query getDistrictById($id: ID) {
  district(id: $id) {
    data {
      id
      attributes {
        district_name
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
      }
    }
  }
}
`;

export const getAllDistrict = gql`
query getAllDistrict {
  districts(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        district_name
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
      }
    }
  }
}
`;

export const getTehsils = gql`
query getAllTehsils($filter: TehsilFiltersInput) {
  tehsils(filters: $filter, pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        tehsil_name
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
      }
    }
  }
}
`;

export const getTehsilById = gql`
query getTehsilById($id: ID) {
  tehsil(id: $id) {
    data {
      id
      attributes {
        tehsil_name
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
      }
    }
  }
}
`;

export const getAllTehsils = gql`
query getAllTehsils{
  tehsils(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        tehsil_name
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
      }
    }
  }
}
`;

export const getUnionCouncils = gql`
query getAllUnionCouncils($filter: UnionCouncilFiltersInput) {
  unionCouncils(filters: $filter, pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        union_council_name
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
      }
    }
  }
}
`;

export const getUnionCouncilById = gql`
query getUnionCouncilById($id: ID) {
  unionCouncil(id: $id) {
    data {
      id
      attributes {
        union_council_name
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
      }
    }
  }
}
`;

export const getAllUnionCouncils = gql`
query getAllUnionCouncils{
  unionCouncils(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        union_council_name
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
      }
    }
  }
}
`;

export const getUsers = gql`
query getAllUser($filter: UsersPermissionsUserFiltersInput) {
  usersPermissionsUsers(
    filters: $filter,
    pagination: { limit: 100000 }
  ) {
    data {
      id
      attributes {
        username
        email
        provider
        address
        full_name
        mobile_number
        user_type {
          data {
            id
            attributes {
              type
            }
          }
        }
        department {
          data {
            id
            attributes {
              name
            }
          }
        }
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
      }
    }
  }
}`;

export const getUserById = gql`
query getUserById($id: ID) {
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        username
        email
        provider
        address
        full_name
        mobile_number
        user_type {
          data {
            id
            attributes {
              type
            }
          }
        }
        department {
          data {
            id
            attributes {
              name
            }
          }
        }
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
        division {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        district {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsil {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_council {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
      }
    }
  }
}
`;

export const getRoles = gql`
query getAllRoles($filter: UsersPermissionsRoleFiltersInput) {
  usersPermissionsRoles(
    pagination: { limit: 100000 }
    filters: $filter
  ) {
    data {
      id
      attributes {
        name
        description
        type
      }
    }
  }
}`;

export const getDepartments = gql`
query getAllDepartments($filter: DepartmentFiltersInput) {
  departments(filters: $filter, pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`;

export const getAllUserTypes = gql`
query getAllUserTypes{
  userTypes {
    data {
      id
      attributes {
        type
      }
    }
  }
}`;

export const getEvents = gql`
query getAllEvents {
  events(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
        description
        from_date
        to_date
        divisions {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        districts {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsils {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_councils {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
      }
    }
  }
}
`;

export const getEventById = gql`
query getEventById($id: ID) {
  event(id: $id) {
    data {
      id
      attributes {
        name
        description
        from_date
        to_date
        divisions {
          data {
            id
            attributes {
              division_name
            }
          }
        }
        districts {
          data {
            id
            attributes {
              district_name
            }
          }
        }
        tehsils {
          data {
            id
            attributes {
              tehsil_name
            }
          }
        }
        union_councils {
          data {
            id
            attributes {
              union_council_name
            }
          }
        }
      }
    }
  }
}
`;


export const getAllPrimaryAreas = gql`
query getAllPrimaryAreas {
  primaryAreas(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getAllPrimaryAreaById = gql`
query getAllPrimaryAreaById($id: ID) {
  primaryArea(id: $id) {
    data {
      id
      attributes {
        name
        createdAt
        updatedAt
      }
    }
  }
}
`;

export const getAllSecondaryAreas = gql`
query getAllSecondaryAreas {
  secondaryAreas(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
        createdAt
        updatedAt
      }
    }
  }
}

`;

export const getAllSecondaryAreaById = gql`
query getAllSecondaryAreaById($id: ID) {
  secondaryArea(id: $id) {
    data {
      id
      attributes {
        name
        createdAt
        updatedAt
      }
    }
  }
}

`;


export const getProductTypes = gql`
query getAllProductTypes {
  productTypes(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getProductTypeById = gql`
query getProductTypeById($id: ID) {
  productType(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getProductNames = gql`
query getAllProductNames {
  productNames(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getProductNameById = gql`
query getProductNameById($id: ID) {
  productName(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getProductCategories = gql`
query getAllProductCategories {
  productCategories(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getProductCategoryById = gql`
query getProductCategoryById($id: ID) {
  productCategory(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getAllClasses = gql`
query getAllClasses {
  classes(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`;

export const getCasualtyTypes = gql`
query getCasualtyTypes {
  casualtyTypes(pagination: { limit: 100000 }, sort: "id:ASC") {
    data {
      id
      attributes {
        name
        chart_color
        createdAt    		
        updatedAt
      }
    }
  }
}`;

export const getCasualtyTypeById = gql`
query getCasualtyTypeById($id: ID) {
  casualtyType(id: $id) {
    data {
      id
      attributes {
        name
        chart_color
        createdAt    		
        updatedAt
      }
    }
  }
}`;

export const getInfrastructureTypes = gql`
query getAllInfrastructureTypes {
  infrastructureTypes(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getInfrastructureTypeById = gql`
query getInfrastructureTypeById($id: ID) {
  infrastructureType(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;


export const getAgricultureTypes = gql`
query getAllAgricultureTypes {
  agricultureTypes(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getAgricultureTypeById = gql`
query getAgricultureTypeById($id: ID) {
  agricultureType(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getLivestockTypes = gql`
query getAllLivestockTypes {
  livestockTypes(pagination: { limit: 100000 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;

export const getLivestockTypeById = gql`
query getLivestockTypeById($id: ID) {
  livestockType(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
