import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from "../../reduxToolkit/store";
import './index.css';
import MainLogo from '../../assets/images/MainLogo@3x.webp';
import UserIcon from '../../assets/images/UserIcon.png';
import tuning from '../../assets/images/tuning.svg';
// import { Menu, Avatar } from "antd";
import type { MenuProps } from 'antd';
import { Button, Dropdown, Space, Badge, Drawer, Menu } from 'antd';
import { UserOutlined, LogoutOutlined, BellOutlined, MenuOutlined, QuestionOutlined } from "@ant-design/icons";
import { BiSolidBell } from "react-icons/bi";
import { IoPerson } from "react-icons/io5";
import { logout, changeUserRoll } from '../../reduxToolkit/authSlice';
import { useSelector, useDispatch } from 'react-redux';

const Header = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, editorMode, userData } = useSelector((state: RootState) => state.auth);
    const [visible, setVisible] = useState(false);
    const [activeItem, setActiveItem] = useState("/");

    const showDrawer = () => {
        setVisible(!visible);
    };
    const items: MenuProps['items'] = [
        {
            key: '1',
            type: 'group',
            label: <div style={{ color: 'black' }}>{`${userData.full_name ? userData.full_name : userData.firstname + " " + userData.lastname}`}<br /><small style={{ color: 'rgb(53, 122, 56)' }}>{userData?.division?.division_name} Division Viewer</small></div>,
            children: [
                {
                    type: 'divider',
                },
                {
                    label: <span>Profile Setting</span>,
                    key: '3',
                    icon: <img src={tuning} style={{ width: '1rem', height: '1rem' }} />,
                },
                // (userData?.editorAccess) ? {
                //     label: <span> {editorMode ? "Change Role to Viewer" : "Change Role to Admin"}</span>,
                //     key: '4',
                //     icon: <QuestionOutlined />,
                // } : null,
                {
                    label: <span>Log out</span>,
                    key: '5',
                    icon: <LogoutOutlined />,
                }
            ],
        },
    ];

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key === "1") {

        } else if (e.key === "4") {
            dispatch(changeUserRoll(editorMode ? "view" : "editor"));
        } else if (e.key === "5") {
            dispatch(logout());
        }
    };

    const location = useLocation();
    const navigate = useNavigate();
    console.log('userData', userData);

    const isHome = location.pathname === "/divisionadmin" || location.pathname === "/dashboard/inventory" || location.pathname === "/dashboard/lossessdamages";
    return (
        <div className="header">
            <div className="logo-container" onClick={() => navigate("/users")}>
                <div className="logo">
                    <img src={MainLogo} alt="Logo2" />
                </div>
            </div>
            <div className="nav-links">
                <ul>
                    <li>{`${userData.full_name ? userData.full_name : (userData?.firstname ?? "") + " " + (userData?.lastname ?? "")}`}</li>
                    {location?.pathname === '/login' ? false : (isAuthenticated ? (
                        <ul>
                            <li>
                                <Dropdown menu={{ items, onClick: handleMenuClick }} placement="bottom">
                                    <a onClick={(e) => e.preventDefault()}>
                                        {/* <UserOutlined /> */}
                                        <div className="UserIconCont">
                                            <img src={UserIcon} alt="" />
                                        </div>
                                    </a>
                                </Dropdown>
                            </li>
                        </ul>
                    ) : (
                        <li>
                            <Button className="nav-login-btn" onClick={() => navigate("/login")} icon={<IoPerson className="nav-login-btn-icon" />}>
                                Login
                            </Button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="nav-drawer">
                <Button className="menuButton" type="text" onClick={showDrawer}>
                    <MenuOutlined />
                </Button>
                <Drawer
                    title={<div className="logo">
                        <img src={MainLogo} alt="Logo2" />
                    </div>}

                    placement="right"
                    closable={true}
                    onClose={showDrawer}
                    visible={visible}
                    style={{ zIndex: 99999 }}
                >
                    <Menu mode="inline">
                        <Menu.Item key="Home">Home</Menu.Item>
                        {/* <Menu.Item key="AboutUs">About Us</Menu.Item>
                        <Menu.Item key="ContactUs">Contact Us</Menu.Item> */}
                        {location?.pathname === '/login' ? false : (isAuthenticated ? (
                            <>
                                <Menu.Item icon={<Space size="small">
                                    <Badge count={1}>
                                        Notification
                                        <BiSolidBell />
                                    </Badge>
                                </Space>} key="Notification" />
                                {(userData?.editorAccess) && <Menu.Item onClick={() => dispatch(changeUserRoll(editorMode ? "view" : "editor"))} icon={<Space size="small">
                                    {editorMode ? "Change Role to Viewer" : "Change Role to Admin"}
                                    {/* <LogoutOutlined /> */}
                                </Space>} key="modeChange" />}
                                <Menu.Item onClick={() => dispatch(logout())} icon={<Space size="small">
                                    Logout
                                    <LogoutOutlined />
                                </Space>} key="Logout" />
                            </>
                        ) : (
                            <Menu.Item onClick={() => navigate("/login")} icon={<Space>Login<UserOutlined /></Space>} key="Login" />
                        ))}
                    </Menu>
                </Drawer>
            </div>
        </div>
    );
};

export default Header;