import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import * as XLSX from 'xlsx';
import "./style.css";
import { fetchAllAgricultureTypes, fetchAllDistrictData, fetchAllEvents, fetchAllInfrastructureTypes, fetchAllLivestockTypes, fetchAllPrimaryAreas, fetchAllSecondaryAreas, fetchAllTehsilData, fetchAllUnionCouncilData, fetchClassesData, fetchHumanCasualtieTypeData, fetchProductCategoriesData, fetchProductNamesData, fetchProductTypesData } from '../../reduxToolkit/entitiesSlice';
import { createFoodDamageData, createHumanCasualtieData, createInfrastructureDamageData, createInventoryData, createLivestockDamageData, createManufacturerData, createNGOData, createSchoolData, createSupplierData, createVolunteerData } from '../../reduxToolkit/importSlice';
import { toast } from '../../components/Toast';

const ImportComponent = () => {
    const dispatch = useDispatch<any>();
    const {
        divisionList,
        allDistrictList,
        alltehsilList,
        allUnionCouncilList,
        primaryAreasList,
        secondaryAreasList,
        productCategorieList,
        productNameList,
        productTypeList,
        classList,
        humanCasualtieType,
        eventList,
        infrastructureTypeList,
        agricultureTypeList,
        livestockTypeList
    } = useSelector((state: RootState) => state.entities);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchAllDistrictData());
        dispatch(fetchAllTehsilData());
        dispatch(fetchAllUnionCouncilData());
        dispatch(fetchAllPrimaryAreas());
        dispatch(fetchAllSecondaryAreas());
        dispatch(fetchProductCategoriesData());
        dispatch(fetchProductNamesData());
        dispatch(fetchProductTypesData());
        dispatch(fetchClassesData());
        dispatch(fetchHumanCasualtieTypeData());
        dispatch(fetchAllEvents());
        dispatch(fetchAllInfrastructureTypes());
        dispatch(fetchAllAgricultureTypes());
        dispatch(fetchAllLivestockTypes());
    }, [dispatch]);

    const handleClick = (id: string) => {
        document.getElementById(id)?.click();
    };

    function excelDateToISODate(serial: any) {
        let date = new Date((serial - 25569) * 86400 * 1000);
        return date.toISOString().split('T')[0]; // Returns the date part in YYYY-MM-DD format
    }

    const UploadNgo = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ? `${row[index]}` : null;
                    });
                    return rowData;
                });

                const processedData = rows.map(row => {
                    const primaryAreaIds = ((row && row.primary_areas) ? row.primary_areas.split(',') : []).map((area: any) =>
                        primaryAreasList.find((primaryArea: any) => primaryArea?.attributes?.name?.trim() === area?.trim())?.id
                    ).filter((id: any) => id !== undefined);
                    const secondaryAreaIds = ((row && row.secondary_areas) ? row.secondary_areas.split(',') : []).map((area: any) =>
                        secondaryAreasList.find((primaryArea: any) => primaryArea?.attributes?.name?.trim() === area?.trim())?.id
                    ).filter((id: any) => id !== undefined);

                    return {
                        ...row,
                        divisionId: divisionList.find((division: any) => division?.attributes?.division_name === row.division)?.id || null,
                        districtId: allDistrictList.find((district: any) => district?.attributes?.district_name === row.district)?.id || null,
                        tehsilId: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.tehsil)?.id || null,
                        union_councilId: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row.union_council)?.id || null,
                        primary_areasId: primaryAreaIds.length > 0 ? primaryAreaIds : null,
                        secondary_areasId: secondaryAreaIds.length > 0 ? secondaryAreaIds : null,
                    };
                });
                for (let i = 0; i < processedData.length; i++) {
                    try {
                        const element: any = processedData[i];
                        const obj: any = {
                            "divisionId": element.divisionId,
                            "districtId": element.districtId,
                            "ngo_name": element.ngo_name,
                            "postal_address": element.postal_address,
                            "primary_areasId": element.primary_areasId,
                            "secondary_areasId": element.secondary_areasId,
                            "website": element.website,
                            "license_registration_number": element.license_registration_number,
                            "year_established": element.year_established,
                            "organizational_head": element.organizational_head,
                            "ngo_contact_person_in_district": element.ngo_contact_person_in_district,
                            "ngo_contact_person_mobile_number_in_district": element.ngo_contact_person_mobile_number_in_district ? Number(element.ngo_contact_person_mobile_number_in_district) : null,
                            "ngo_contact_number": element.ngo_contact_number ? Number(element.ngo_contact_number) : null,
                            "latitude": element.latitude ? Number(element.latitude) : null,
                            "longitude": element.longitude ? Number(element.longitude) : null,
                            "tehsilId": element.tehsilId,
                            "union_councilId": element.union_councilId,
                            "address": element.address,
                            "stage": null,
                        };
                        await dispatch(createNGOData(obj));
                    } catch (error: any) {
                        console.log(error.message);
                    }
                }
                setIsUploading(false);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
        toast('success', "Import NGO successfull");
        event.target.value = '';
    };

    const UploadVolunteers = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ? `${row[index]}` : null;
                    });
                    return rowData;
                });

                const processedData = rows.map(row => {
                    return {
                        ...row,
                        divisionId: divisionList.find((division: any) => division?.attributes?.division_name === row.division)?.id || null,
                        districtId: allDistrictList.find((district: any) => district?.attributes?.district_name === row.district)?.id || null,
                        tehsilId: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.tehsil)?.id || null,
                        union_councilId: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row.union_council)?.id || null,
                    };
                });
                for (let i = 0; i < processedData.length; i++) {
                    try {
                        const element: any = processedData[i];
                        const obj: any = {
                            "name": element.name,
                            "divisionId": element.divisionId,
                            "districtId": element.districtId,
                            "cnic_number": element.cnic_number ? Number(element.cnic_number) : null,
                            "postal_address": element.postal_address,
                            "website": element.website,
                            "contact_person": element.contact_person,
                            "contact_person_mobile": element.contact_person_mobile ? Number(element.contact_person_mobile) : null,
                            "latitude": element.latitude ? Number(element.latitude) : null,
                            "longitude": element.longitude ? Number(element.longitude) : null,
                            "tehsilId": element.tehsilId,
                            "union_councilId": element.union_councilId,
                            "address": element.address,
                            "stage": null,
                        };
                        await dispatch(createVolunteerData(obj));
                    } catch (error: any) {
                        console.log(error.message);
                    }
                }
                setIsUploading(false);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
        toast('success', "Import Volunteers successfull");
        event.target.value = '';
    };

    const UploadManufacturers = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ? `${row[index]}` : null;
                    });
                    return rowData;
                });

                const processedData = rows.map(row => {
                    const primaryAreaIds = ((row && row.primary_areas) ? row.primary_areas.split(',') : []).map((area: any) =>
                        primaryAreasList.find((primaryArea: any) => primaryArea?.attributes?.name?.trim() === area?.trim())?.id
                    ).filter((id: any) => id !== undefined);
                    const secondaryAreaIds = ((row && row.secondary_areas) ? row.secondary_areas.split(',') : []).map((area: any) =>
                        secondaryAreasList.find((primaryArea: any) => primaryArea?.attributes?.name?.trim() === area?.trim())?.id
                    ).filter((id: any) => id !== undefined);

                    return {
                        ...row,
                        divisionId: divisionList.find((division: any) => division?.attributes?.division_name === row.division)?.id || null,
                        districtId: allDistrictList.find((district: any) => district?.attributes?.district_name === row.district)?.id || null,
                        tehsilId: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.tehsil)?.id || null,
                        union_councilId: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row.union_council)?.id || null,
                        primary_areasId: primaryAreaIds.length > 0 ? primaryAreaIds : null,
                        secondary_areasId: secondaryAreaIds.length > 0 ? secondaryAreaIds : null,
                    };
                });
                for (let i = 0; i < processedData.length; i++) {
                    try {
                        const element: any = processedData[i];
                        const obj: any = {
                            "name": element.name,
                            "divisionId": element.divisionId,
                            "districtId": element.districtId,
                            "postal_address": element.postal_address,
                            "primary_areasId": element.primary_areasId,
                            "secondary_areasId": element.secondary_areasId,
                            "website": element.website,
                            "registration_number": element.registration_number,
                            "year_established": element.year_established,
                            "organizational_head": element.organizational_head,
                            "contact_person_in_district": element.contact_person_in_district,
                            "contact_person_mobile_number_in_district": element.contact_person_mobile_number_in_district ? Number(element.contact_person_mobile_number_in_district) : null,
                            "contact_number": element.contact_number ? Number(element.contact_number) : null,
                            "latitude": element.latitude ? Number(element.latitude) : null,
                            "longitude": element.longitude ? Number(element.longitude) : null,
                            "tehsilId": element.tehsilId,
                            "union_councilId": element.union_councilId,
                            "address": element.address,
                            "stage": null,
                        };
                        await dispatch(createManufacturerData(obj));
                    } catch (error: any) {
                        console.log(error.message);
                    }
                }
                setIsUploading(false);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
        toast('success', "Import Manufacturers successfull");
        event.target.value = '';
    };

    const UploadSuppliers = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ? `${row[index]}` : null;
                    });
                    return rowData;
                });

                const processedData = rows.map(row => {
                    const primaryAreaIds = ((row && row.primary_areas) ? row.primary_areas.split(',') : []).map((area: any) =>
                        primaryAreasList.find((primaryArea: any) => primaryArea?.attributes?.name?.trim() === area?.trim())?.id
                    ).filter((id: any) => id !== undefined);
                    const secondaryAreaIds = ((row && row.secondary_areas) ? row.secondary_areas.split(',') : []).map((area: any) =>
                        secondaryAreasList.find((primaryArea: any) => primaryArea?.attributes?.name?.trim() === area?.trim())?.id
                    ).filter((id: any) => id !== undefined);

                    return {
                        ...row,
                        divisionId: divisionList.find((division: any) => division?.attributes?.division_name === row.division)?.id || null,
                        districtId: allDistrictList.find((district: any) => district?.attributes?.district_name === row.district)?.id || null,
                        tehsilId: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.tehsil)?.id || null,
                        union_councilId: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row.union_council)?.id || null,
                        primary_areasId: primaryAreaIds.length > 0 ? primaryAreaIds : null,
                        secondary_areasId: secondaryAreaIds.length > 0 ? secondaryAreaIds : null,
                    };
                });
                for (let i = 0; i < processedData.length; i++) {
                    try {
                        const element: any = processedData[i];
                        const obj: any = {
                            "name": element.name,
                            "divisionId": element.divisionId,
                            "districtId": element.districtId,
                            "postal_address": element.postal_address,
                            "primary_areasId": element.primary_areasId,
                            "secondary_areasId": element.secondary_areasId,
                            "website": element.website,
                            "registration_number": element.registration_number,
                            "year_established": element.year_established,
                            "organizational_head": element.organizational_head,
                            "contact_person_in_district": element.contact_person_in_district,
                            "contact_person_mobile_number_in_district": element.contact_person_mobile_number_in_district ? Number(element.contact_person_mobile_number_in_district) : null,
                            "contact_number": element.contact_number ? Number(element.contact_number) : null,
                            "latitude": element.latitude ? Number(element.latitude) : null,
                            "longitude": element.longitude ? Number(element.longitude) : null,
                            "tehsilId": element.tehsilId,
                            "union_councilId": element.union_councilId,
                            "address": element.address,
                            "stage": null,
                        };
                        await dispatch(createSupplierData(obj));
                    } catch (error: any) {
                        console.log(error.message);
                    }
                }
                setIsUploading(false);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
        toast('success', "Import Suppliers successfull");
        event.target.value = '';
    };

    const UploadSchools = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ? `${row[index]}` : null;
                    });
                    return rowData;
                });

                const processedData = rows.map(row => {
                    return {
                        ...row,
                        divisionId: divisionList.find((division: any) => division?.attributes?.division_name === row.division)?.id || null,
                        districtId: allDistrictList.find((district: any) => district?.attributes?.district_name === row.district)?.id || null,
                        tehsilId: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.tehsil)?.id || null,
                        union_councilId: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row.union_council)?.id || null,
                    };
                });
                for (let i = 0; i < processedData.length; i++) {
                    try {
                        const element: any = processedData[i];
                        const obj: any = {
                            "divisionId": element.divisionId,
                            "districtId": element.districtId,
                            "tehsilId": element.tehsilId,
                            "union_councilId": element.union_councilId,
                            "name": element.name,
                            "address": element.address,
                            "semis_code": element.semis_code ? Number(element.semis_code) : null,
                            "latitude": element.latitude ? Number(element.latitude) : null,
                            "longitude": element.longitude ? Number(element.longitude) : null,
                            "area_type": element.area_type,
                            "building_condition": element.building_condition,
                            "number_of_rooms": element.number_of_rooms ? Number(element.number_of_rooms) : null,
                            "electricity": element.electricity === "Yes" ? true : false,
                            "toilet": element.toilet === "Yes" ? true : false,
                            "drinking_water": element.drinking_water === "Yes" ? true : false,
                            "boundary_wall": element.boundary_wall === "Yes" ? true : false,
                        };
                        await dispatch(createSchoolData(obj));
                    } catch (error: any) {
                        console.log(error.message);
                    }
                }
                setIsUploading(false);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
        toast('success', "Import Schools successfull");
        event.target.value = '';
    };

    const UploadInventory = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ? `${row[index]}` : null;
                    });
                    return rowData;
                });

                const processedData = rows.map(row => {
                    return {
                        ...row,
                        divisionId: divisionList.find((division: any) => division?.attributes?.division_name === row.division)?.id || null,
                        districtId: allDistrictList.find((district: any) => district?.attributes?.district_name === row.district)?.id || null,
                        tehsilId: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.tehsil)?.id || null,
                        union_councilId: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row.union_council)?.id || null,
                        classId: classList.find((union: any) => union?.attributes?.name === row.class)?.id || null,
                        product_typeId: productTypeList.find((union: any) => union?.attributes?.name === row.product_type)?.id || null,
                        product_nameId: productNameList.find((union: any) => union?.attributes?.name === row.product_name)?.id || null,
                        product_categoryId: productCategorieList.find((union: any) => union?.attributes?.name === row.product_category)?.id || null,
                    };
                });
                for (let i = 0; i < processedData.length; i++) {
                    try {
                        const element: any = processedData[i];
                        const obj: any = {
                            "divisionId": element.divisionId,
                            "districtId": element.districtId,
                            "product_typeId": element.product_typeId,
                            "product_nameId": element.product_nameId,
                            "product_categoryId": element.product_categoryId,
                            "classId": element.classId,
                            "product_section": element.product_section,
                            "received_quantity": element.received_quantity ? Number(element.received_quantity) : null,
                            "consumed_quantity": element.consumed_quantity ? Number(element.consumed_quantity) : null,
                            "on_hand_quantity": element.on_hand_quantity ? Number(element.on_hand_quantity) : null,
                            "warehouse_address": element.warehouse_address,
                            "description": element.description,
                            "unit_of_measure": element.unit_of_measure,
                            "product_code": element.product_code,
                            "product_barcode": element.product_barcode,
                            "product_qr_code": element.product_qr_code,
                            "product_brand": element.product_brand,
                            "product_company": element.product_company,
                            "induction_date": element.induction_date ? excelDateToISODate(element.induction_date) : null,
                            "warranty_year": element.warranty_year,
                            "expiry_date": element.expiry_date ? excelDateToISODate(element.expiry_date) : null,
                            "latitude": element.latitude ? Number(element.latitude) : null,
                            "longitude": element.longitude ? Number(element.longitude) : null,
                            "tehsilId": element.tehsilId,
                            "union_councilId": element.union_councilId,
                            "stage": null,
                        };
                        await dispatch(createInventoryData(obj));
                    } catch (error: any) {
                        console.log(error.message);
                    }
                }
                setIsUploading(false);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
        toast('success', "Import Inventory successfull");
        event.target.value = '';
    };

    const UploadHumanCasualtie = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ? `${row[index]}` : null;
                    });
                    return rowData;
                });

                const processedData = rows.map(row => {
                    return {
                        ...row,
                        divisionId: divisionList.find((division: any) => division?.attributes?.division_name === row.division)?.id || null,
                        districtId: allDistrictList.find((district: any) => district?.attributes?.district_name === row.district)?.id || null,
                        tehsilId: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.tehsil)?.id || null,
                        union_councilId: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row.union_council)?.id || null,
                        casualty_typeId: humanCasualtieType.find((union: any) => union?.attributes?.name === row.casualty_type)?.id || null,
                        eventId: eventList.find((union: any) => union?.attributes?.name === row.event)?.id || null,
                    };
                });
                for (let i = 0; i < processedData.length; i++) {
                    try {
                        const element: any = processedData[i];
                        const obj: any = {
                            "divisionId": element.divisionId,
                            "districtId": element.districtId,
                            "casualty_typeId": element.casualty_typeId,
                            "eventId": element.eventId,
                            "latitude": element.latitude ? Number(element.latitude) : null,
                            "longitude": element.longitude ? Number(element.longitude) : null,
                            "quantity": element.quantity ? Number(element.quantity) : null,
                            "tehsilId": element.tehsilId,
                            "union_councilId": element.union_councilId,
                            "address": element.address,
                            "stage": null,
                        };
                        await dispatch(createHumanCasualtieData(obj));
                    } catch (error: any) {
                        console.log(error.message);
                    }
                }
                setIsUploading(false);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
        toast('success', "Import Human Casualties successfull");
        event.target.value = '';
    };

    const UploadInfrastructureDamage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ? `${row[index]}` : null;
                    });
                    return rowData;
                });

                const processedData = rows.map(row => {
                    return {
                        ...row,
                        divisionId: divisionList.find((division: any) => division?.attributes?.division_name === row.division)?.id || null,
                        districtId: allDistrictList.find((district: any) => district?.attributes?.district_name === row.district)?.id || null,
                        tehsilId: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.tehsil)?.id || null,
                        union_councilId: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row.union_council)?.id || null,
                        infrastructure_typeId: infrastructureTypeList.find((union: any) => union?.attributes?.name === row.infrastructure_type)?.id || null,
                        eventId: eventList.find((union: any) => union?.attributes?.name === row.event)?.id || null,
                    };
                });
                for (let i = 0; i < processedData.length; i++) {
                    try {
                        const element: any = processedData[i];
                        const obj: any = {
                            "divisionId": element.divisionId,
                            "districtId": element.districtId,
                            "infrastructure_typeId": element.infrastructure_typeId,
                            "eventId": element.eventId,
                            "damaged": element.damaged,
                            "restored": element.restored,
                            "latitude": element.latitude ? Number(element.latitude) : null,
                            "longitude": element.longitude ? Number(element.longitude) : null,
                            "tehsilId": element.tehsilId,
                            "union_councilId": element.union_councilId,
                            "address": element.address,
                            "stage": null,
                        };
                        await dispatch(createInfrastructureDamageData(obj));
                    } catch (error: any) {
                        console.log(error.message);
                    }
                }
                setIsUploading(false);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
        toast('success', "Import Human Casualties successfull");
        event.target.value = '';
    };

    const UploadFoodDamage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ? `${row[index]}` : null;
                    });
                    return rowData;
                });

                const processedData = rows.map(row => {
                    return {
                        ...row,
                        divisionId: divisionList.find((division: any) => division?.attributes?.division_name === row.division)?.id || null,
                        districtId: allDistrictList.find((district: any) => district?.attributes?.district_name === row.district)?.id || null,
                        tehsilId: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.tehsil)?.id || null,
                        union_councilId: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row.union_council)?.id || null,
                        agriculture_typeId: agricultureTypeList.find((union: any) => union?.attributes?.name === row.agriculture_type)?.id || null,
                        eventId: eventList.find((union: any) => union?.attributes?.name === row.event)?.id || null,
                    };
                });
                for (let i = 0; i < processedData.length; i++) {
                    try {
                        const element: any = processedData[i];
                        const obj: any = {
                            "divisionId": element.divisionId,
                            "districtId": element.districtId,
                            "agriculture_typeId": element.agriculture_typeId,
                            "eventId": element.eventId,
                            "acre": element.acre,
                            "cost": element.cost ? Number(element.cost) : null,
                            "latitude": element.latitude ? Number(element.latitude) : null,
                            "longitude": element.longitude ? Number(element.longitude) : null,
                            "tehsilId": element.tehsilId,
                            "union_councilId": element.union_councilId,
                            "address": element.address,
                            "stage": null,
                        };
                        await dispatch(createFoodDamageData(obj));
                    } catch (error: any) {
                        console.log(error.message);
                    }
                }
                setIsUploading(false);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
        toast('success', "Import Human Casualties successfull");
        event.target.value = '';
    };

    const UploadLivestockDamage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true);
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1).map((row: any) => {
                    const rowData: { [key: string]: any; } = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ? `${row[index]}` : null;
                    });
                    return rowData;
                });

                const processedData = rows.map(row => {
                    return {
                        ...row,
                        divisionId: divisionList.find((division: any) => division?.attributes?.division_name === row.division)?.id || null,
                        districtId: allDistrictList.find((district: any) => district?.attributes?.district_name === row.district)?.id || null,
                        tehsilId: alltehsilList.find((tehsil: any) => tehsil?.attributes?.tehsil_name === row.tehsil)?.id || null,
                        union_councilId: allUnionCouncilList.find((union: any) => union?.attributes?.union_council_name === row.union_council)?.id || null,
                        casualty_typeId: humanCasualtieType.find((union: any) => union?.attributes?.name === row.casualty_type)?.id || null,
                        livestock_typeId: livestockTypeList.find((union: any) => union?.attributes?.name === row.livestock_type)?.id || null,
                        eventId: eventList.find((union: any) => union?.attributes?.name === row.event)?.id || null,
                    };
                });
                for (let i = 0; i < processedData.length; i++) {
                    try {
                        const element: any = processedData[i];
                        const obj: any = {
                            "divisionId": element.divisionId,
                            "districtId": element.districtId,
                            "livestock_typeId": element.livestock_typeId,
                            "eventId": element.eventId,
                            "total_quantity": element.total_quantity ? Number(element.total_quantity) : null,
                            "cost": element.cost ? Number(element.cost) : null,
                            "latitude": element.latitude ? Number(element.latitude) : null,
                            "longitude": element.longitude ? Number(element.longitude) : null,
                            "casualty_typeId": element.casualty_typeId,
                            "tehsilId": element.tehsilId,
                            "union_councilId": element.union_councilId,
                            "address": element.address,
                            "stage": null,
                        };
                        await dispatch(createLivestockDamageData(obj));
                    } catch (error: any) {
                        console.log(error.message);
                    }
                }
                setIsUploading(false);
            } catch (error) {
                console.error("Error reading or processing file:", error);
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };

        reader.readAsArrayBuffer(file);
        toast('success', "Import Human Casualties successfull");
        event.target.value = '';
    };

    return (
        <div className='button-container'>
            <Button className='button-item sand-btn-text' onClick={() => handleClick("NGOfileInput")}>
                <input
                    type="file"
                    id="NGOfileInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={UploadNgo}
                />
                NGO Mapping
            </Button>
            <Button className='button-item sand-btn-text' onClick={() => handleClick("VolunteersfileInput")}>
                <input
                    type="file"
                    id="VolunteersfileInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={UploadVolunteers}
                />
                Volunteers Mapping
            </Button>
            <Button className='button-item sand-btn-text' onClick={() => handleClick("ManufacturersfileInput")}>
                <input
                    type="file"
                    id="ManufacturersfileInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={UploadManufacturers}
                />
                Manufacturers Mapping
            </Button>
            <Button className='button-item sand-btn-text' onClick={() => handleClick("SuppliersfileInput")}>
                <input
                    type="file"
                    id="SuppliersfileInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={UploadSuppliers}
                />
                Suppliers Mapping
            </Button>
            <Button className='button-item sand-btn-text' onClick={() => handleClick("SchoolsfileInput")}>
                <input
                    type="file"
                    id="SchoolsfileInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={UploadSchools}
                />
                School Mapping
            </Button>
            <Button className='button-item sand-btn-text' onClick={() => handleClick("InventoryfileInput")}>
                <input
                    type="file"
                    id="InventoryfileInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={UploadInventory}
                />
                Inventory
            </Button>
            <Button className='button-item sand-btn-text' onClick={() => handleClick("HumanCasualtiefileInput")}>
                <input
                    type="file"
                    id="HumanCasualtiefileInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={UploadHumanCasualtie}
                />
                Human Casualtie
            </Button>
            <Button className='button-item sand-btn-text' onClick={() => handleClick("InfrastructureDamagefileInput")}>
                <input
                    type="file"
                    id="InfrastructureDamagefileInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={UploadInfrastructureDamage}
                />
                Infrastructure Damage
            </Button>
            <Button className='button-item sand-btn-text' onClick={() => handleClick("FoodDamagefileInput")}>
                <input
                    type="file"
                    id="FoodDamagefileInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={UploadFoodDamage}
                />
                Food Damage
            </Button>
            <Button className='button-item sand-btn-text' onClick={() => handleClick("LivestockDamagefileInput")}>
                <input
                    type="file"
                    id="LivestockDamagefileInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls"
                    onChange={UploadLivestockDamage}
                />
                Live stock Damage
            </Button>
        </div>
    );
};

export default ImportComponent;