import React, { useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import { Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { createDistrictData } from '../../reduxToolkit/entitiesSlice';
import hierarchy_icon from "../../assets/images/hierarchy_icon.png";
import './index.css'; // Import CSS for styling

const DistrictForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const { divisionList } = useSelector((state: RootState) => state.entities);
    const [newData, setNewData] = useState<{ [key: string]: any; }>({});
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const onSave = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            const obj: any = {
                "divisionId": newData.divisionId,
                "district_name": newData.district_name,
            };
            if (Object.values(obj).some(value => !value)) {
                setIsCheckRequired(true);
                throw new Error('Please fill the all highlighted fields');
            }
            const res = await dispatch(createDistrictData(obj));
            if (res.type !== "entities/createDistrictData/rejected") {
                navigate(-1);
                toast('success', "District create successfully");
            } else {
                setIsCreating(false);
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={hierarchy_icon} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Hierarchy</span>
                        </div>
                    </div>
                    <div className='form-send-btn' onClick={onSave}>
                        <span className='sand-btn-text'>Save Information</span>
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <span>Create District</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={newData.division || undefined}
                            status={(isCheckRequired && !newData.division) ? 'error' : ""}
                            placeholder="Select the Division"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setNewData(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            options={divisionData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Input
                            placeholder="Enter District Name"
                            status={(isCheckRequired && !newData.district_name) ? 'error' : ""}
                            onChange={({ target }) => setNewData(prevState => ({ ...prevState, district_name: target.value }))}
                            value={newData.district_name}
                            className='input' />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default DistrictForm;
