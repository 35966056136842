export const userColumns = [
    {
        title: 'S_NO',
        dataIndex: 'key',
        key: 'key',
        sorter: (a, b) => (a.key ?? 0) - (b.key ?? 0),
    },
    {
        title: 'Full Name',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: (a, b) => (a.full_name ?? '').localeCompare(b.full_name ?? '')
    },
    {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username',
        sorter: (a, b) => (a.username ?? '').localeCompare(b.username ?? '')
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => (a.email ?? '').localeCompare(b.email ?? '')
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
        title: 'Mobile Number',
        dataIndex: 'mobile_number',
        key: 'mobile_number',
        sorter: (a, b) => (a.mobile_number ?? 0) - (b.mobile_number ?? 0),
    },
    {
        title: 'User Type',
        dataIndex: 'user_type',
        key: 'user_type',
        sorter: (a, b) => (a.user_type ?? '').localeCompare(b.user_type ?? '')
    },
    {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        sorter: (a, b) => (a.department ?? '').localeCompare(b.department ?? '')
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        sorter: (a, b) => (a.address ?? '').localeCompare(b.address ?? '')
    },
    {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        sorter: (a, b) => (a.division ?? '').localeCompare(b.division ?? '')
    },
    {
        title: 'District',
        dataIndex: 'district',
        key: 'district',
        sorter: (a, b) => (a.district ?? '').localeCompare(b.district ?? '')
    },
    {
        title: 'Tehsil',
        dataIndex: 'tehsil',
        key: 'tehsil',
        sorter: (a, b) => (a.tehsil ?? '').localeCompare(b.tehsil ?? '')
    },
    {
        title: 'Union Council',
        dataIndex: 'union_council',
        key: 'union_council',
        sorter: (a, b) => (a.union_council ?? '').localeCompare(b.union_council ?? '')
    }
];

export const divisionColumns = [
    {
        title: 'S_NO',
        dataIndex: 'key',
        key: 'key',
        sorter: (a, b) => (a.key ?? 0) - (b.key ?? 0),
    },
    {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        sorter: (a, b) => (a.division ?? '').localeCompare(b.division ?? '')
    },
];

export const districtColumns = [
    {
        title: 'S_NO',
        dataIndex: 'key',
        key: 'key',
        sorter: (a, b) => (a.key ?? 0) - (b.key ?? 0),
    },
    {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        sorter: (a, b) => (a.division ?? '').localeCompare(b.division ?? '')
    },
    {
        title: 'District',
        dataIndex: 'district',
        key: 'district',
        sorter: (a, b) => (a.district ?? '').localeCompare(b.district ?? '')
    },
];

export const tehsilColumns = [
    {
        title: 'S_NO',
        dataIndex: 'key',
        key: 'key',
        sorter: (a, b) => (a.key ?? 0) - (b.key ?? 0),
    },
    {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        sorter: (a, b) => (a.division ?? '').localeCompare(b.division ?? '')
    },
    {
        title: 'District',
        dataIndex: 'district',
        key: 'district',
        sorter: (a, b) => (a.district ?? '').localeCompare(b.district ?? '')
    },
    {
        title: 'Tehsil',
        dataIndex: 'tehsil',
        key: 'tehsil',
        sorter: (a, b) => (a.tehsil ?? '').localeCompare(b.tehsil ?? '')
    },
];

export const unionCouncilColumns = [
    {
        title: 'S_NO',
        dataIndex: 'key',
        key: 'key',
        sorter: (a, b) => (a.key ?? 0) - (b.key ?? 0),
    },
    {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        sorter: (a, b) => (a.division ?? '').localeCompare(b.division ?? '')
    },
    {
        title: 'District',
        dataIndex: 'district',
        key: 'district',
        sorter: (a, b) => (a.district ?? '').localeCompare(b.district ?? '')
    },
    {
        title: 'Tehsil',
        dataIndex: 'tehsil',
        key: 'tehsil',
        sorter: (a, b) => (a.tehsil ?? '').localeCompare(b.tehsil ?? '')
    },
    {
        title: 'Union Council',
        dataIndex: 'union_council',
        key: 'union_council',
        sorter: (a, b) => (a.union_council ?? '').localeCompare(b.union_council ?? '')
    },
];

export const eventColumns = [
    {
        title: 'S_NO',
        dataIndex: 'key',
        key: 'key',
        sorter: (a, b) => (a.key ?? 0) - (b.key ?? 0),
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? '')
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => (a.description ?? '').localeCompare(b.description ?? '')
    },
    {
        title: 'From Date',
        dataIndex: 'from_date',
        key: 'from_date',
        render: (text) => <span>{new Date(text).toLocaleDateString('en-us')}</span>,
        sorter: (a, b) => new Date(a.from_date).getTime() - new Date(b.from_date).getTime()
    },
    {
        title: 'To Date',
        dataIndex: 'to_date',
        key: 'to_date',
        render: (text) => <span>{new Date(text).toLocaleDateString('en-us')}</span>,
        sorter: (a, b) => new Date(a.to_date).getTime() - new Date(b.to_date).getTime()
    },
    {
        title: 'Divisions',
        dataIndex: 'divisions',
        key: 'divisions',
        sorter: (a, b) => {
            const nameA = a.divisions?.join(', ') ?? '';
            const nameB = b.divisions?.join(', ') ?? '';
            return nameA.localeCompare(nameB);
        },
        render: (divisions) => {
            const names = divisions?.map(uc => uc).join(', ') ?? 'N/A';
            return names;
        }
    },
    {
        title: 'Districts',
        dataIndex: 'districts',
        key: 'districts',
        sorter: (a, b) => {
            const nameA = a.districts?.join(', ') ?? '';
            const nameB = b.districts?.join(', ') ?? '';
            return nameA.localeCompare(nameB);
        },
        render: (districts) => {
            const names = districts?.map(uc => uc).join(', ') ?? 'N/A';
            return names;
        }
    },
    {
        title: 'Tehsils',
        dataIndex: 'tehsils',
        key: 'tehsils',
        sorter: (a, b) => {
            const nameA = a.tehsils?.map(uc => uc).join(', ') ?? '';
            const nameB = b.tehsils?.map(uc => uc).join(', ') ?? '';
            return nameA.localeCompare(nameB);
        },
        render: (tehsils) => {
            const names = tehsils?.map(uc => uc).join(', ') ?? 'N/A';
            return names;
        }
    },
    {
        title: 'Union Councils',
        dataIndex: 'union_councils',
        key: 'union_councils',
        sorter: (a, b) => {
            const nameA = a.union_councils?.map(uc => uc).join(', ') ?? '';
            const nameB = b.union_councils?.map(uc => uc).join(', ') ?? '';
            return nameA.localeCompare(nameB);
        },
        render: (union_councils) => {
            const names = union_councils?.map(uc => uc).join(', ') ?? 'N/A';
            return names;
        }
    }
];