// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 0fr));
  grid-gap: 15px;
  margin: 2%;
}

.button-item {
  margin-bottom: 15px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #073042;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .button-container {
    margin-top: 5%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Import/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2DAA2D;EAC3D,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".button-container {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 0fr));\n  grid-gap: 15px;\n  margin: 2%;\n}\n\n.button-item {\n  margin-bottom: 15px;\n  font-family: Montserrat;\n  font-size: 14px;\n  font-weight: 500;\n  color: #073042;\n  cursor: pointer;\n}\n\n@media screen and (max-width: 767px) {\n  .button-container {\n    margin-top: 5%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
