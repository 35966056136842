export const fetchDetailsByCoords = async (lat, lon) => {
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`;
    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.error) {
            return "";
        } else {
            return data;
        }
    } catch (err) {
        console.error("Error fetching city name:", err);
        return "";
    }
};


export const numberFormater = (number = 0) => {
    if (!isFinite(number)) return 0;
    const formated = Number(Number(number).toFixed(2));
    return formated.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const objectValues = (obj) => {
    return Object.keys(obj).map(key => obj[key]);
};


export const emailValidate = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const isValid = emailRegex.test(email);
    return isValid;
};