import { io } from "socket.io-client";
import { addAndUpdateUserRecordFromSocket, deleteUserRecordFromSocket } from "../reduxToolkit/userSlice";
import { store } from "../reduxToolkit/store";
import { addAndUpdateAgricultureTypeRecordFromSocket, addAndUpdateDistrictRecordFromSocket, addAndUpdateDivisionRecordFromSocket, addAndUpdateEventRecordFromSocket, addAndUpdateHumanCasualtieTypeRecordFromSocket, addAndUpdateInfrastructureTypeRecordFromSocket, addAndUpdateLivestockTypeRecordFromSocket, addAndUpdatePrimaryAreaRecordFromSocket, addAndUpdateProductCategoryRecordFromSocket, addAndUpdateProductNameRecordFromSocket, addAndUpdateProductTypeRecordFromSocket, addAndUpdateSecondaryAreaRecordFromSocket, addAndUpdateTehsilRecordFromSocket, addAndUpdateUnionCouncilRecordFromSocket, deleteEntitiesRecordFromSocket } from "../reduxToolkit/entitiesSlice";

const SERVER_URL = 'https://db.pdma.hexalyze.com';

const socket = io(SERVER_URL);

// Action to initialize socket connection and listen for events
export const initializeSocket = () => async (dispatch) => {
    socket.on('connect', () => {
        // user
        socket.on('user:create', ({ data }) => {
            const { recordId } = data;
            const userData = store.getState().auth.userData;
            dispatch(addAndUpdateUserRecordFromSocket({ recordId, userData }));
        });
        socket.on('user:update', ({ data }) => {
            const { recordId } = data;
            const userData = store.getState().auth.userData;
            dispatch(addAndUpdateUserRecordFromSocket({ recordId, userData }));
        });
        socket.on('user:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteUserRecordFromSocket(recordId));
        });
        // division
        socket.on('division:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateDivisionRecordFromSocket(recordId));
        });
        socket.on('division:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateDivisionRecordFromSocket(recordId));
        });
        socket.on('division:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["divisionList"] }));
        });
        // district
        socket.on('district:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateDistrictRecordFromSocket(recordId));
        });
        socket.on('district:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateDistrictRecordFromSocket(recordId));
        });
        socket.on('district:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["allDistrictList", "districtList"] }));
        });
        // tehsil
        socket.on('tehsil:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateTehsilRecordFromSocket(recordId));
        });
        socket.on('tehsil:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateTehsilRecordFromSocket(recordId));
        });
        socket.on('tehsil:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["tehsilList", "alltehsilList"] }));
        });
        // union - council;
        socket.on('union-council:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateUnionCouncilRecordFromSocket(recordId));
        });
        socket.on('union-council:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateUnionCouncilRecordFromSocket(recordId));
        });
        socket.on('union-council:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["unionCouncilList", "allUnionCouncilList"] }));
        });
        // event
        socket.on('event:create', ({ data }) => {
            const { recordId } = data;
            const userData = store.getState().auth.userData;
            dispatch(addAndUpdateEventRecordFromSocket({ recordId, userData }));
        });
        socket.on('event:update', ({ data }) => {
            const { recordId } = data;
            const userData = store.getState().auth.userData;
            dispatch(addAndUpdateEventRecordFromSocket({ recordId, userData }));
        });
        socket.on('event:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["eventList"] }));
        });
        // primary-area
        socket.on('primary-area:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdatePrimaryAreaRecordFromSocket(recordId));
        });
        socket.on('primary-area:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdatePrimaryAreaRecordFromSocket(recordId));
        });
        socket.on('primary-area:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["primaryAreasList"] }));
        });
        // secondary-area
        socket.on('secondary-area:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateSecondaryAreaRecordFromSocket(recordId));
        });
        socket.on('secondary-area:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateSecondaryAreaRecordFromSocket(recordId));
        });
        socket.on('secondary-area:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["secondaryAreasList"] }));
        });
        // product-type
        socket.on('product-type:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductTypeRecordFromSocket(recordId));
        });
        socket.on('product-type:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductTypeRecordFromSocket(recordId));
        });
        socket.on('product-type:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["productTypeList"] }));
        });
        // product-name
        socket.on('product-name:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductNameRecordFromSocket(recordId));
        });
        socket.on('product-name:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductNameRecordFromSocket(recordId));
        });
        socket.on('product-name:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["productNameList"] }));
        });
        // product-category
        socket.on('product-category:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductCategoryRecordFromSocket(recordId));
        });
        socket.on('product-category:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateProductCategoryRecordFromSocket(recordId));
        });
        socket.on('product-category:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["productCategorieList"] }));
        });
        // casualty-type
        socket.on('casualty-type:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateHumanCasualtieTypeRecordFromSocket(recordId));
        });
        socket.on('casualty-type:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateHumanCasualtieTypeRecordFromSocket(recordId));
        });
        socket.on('casualty-type:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["humanCasualtieType"] }));
        });
        // infrastructure-type
        socket.on('infrastructure-type:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateInfrastructureTypeRecordFromSocket(recordId));
        });
        socket.on('infrastructure-type:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateInfrastructureTypeRecordFromSocket(recordId));
        });
        socket.on('infrastructure-type:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["infrastructureTypeList"] }));
        });
        // agriculture-type
        socket.on('agriculture-type:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateAgricultureTypeRecordFromSocket(recordId));
        });
        socket.on('agriculture-type:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateAgricultureTypeRecordFromSocket(recordId));
        });
        socket.on('agriculture-type:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["agricultureTypeList"] }));
        });
        // livestock-type
        socket.on('livestock-type:create', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateLivestockTypeRecordFromSocket(recordId));
        });
        socket.on('livestock-type:update', ({ data }) => {
            const { recordId } = data;
            dispatch(addAndUpdateLivestockTypeRecordFromSocket(recordId));
        });
        socket.on('livestock-type:delete', ({ data }) => {
            const { recordId } = data;
            dispatch(deleteEntitiesRecordFromSocket({ recordId, list: ["livestockTypeList"] }));
        });
    });
};