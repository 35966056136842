import React, { useEffect, useState } from 'react';
import PlusIcon from '../../assets/images/plusIcon.png';
import ReusableTable from '../../components/table';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../reduxToolkit/store";
import SearchSelect from '../../components/selectDropdown';
import { Input } from 'antd';
import Filters from '../../components/top_filter';
import { useNavigate } from 'react-router-dom';
import { eventColumns } from '../../helpers/tableColumns';
import { fetchAllEvents } from '../../reduxToolkit/entitiesSlice';
import './style.css';

const Events: React.FC = () => {
    const { eventList } = useSelector((state: RootState) => state.entities);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedTehsil, setSelectedTehsil] = useState('');
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState('');
    const [selectedParameter, setSelectedParameter] = useState('');
    const [selectedKeyword, setSelectedKeyword] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(fetchAllEvents());
    }, [dispatch]);

    const eventData = alignEventData(eventList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);
    console.log('eventData', eventData);

    return (
        <div className='Inventory-mainCont'>
            <Filters
                setSelectedDivision={setSelectedDivision}
                selectedDivision={selectedDivision}
                setSelectedDistrict={setSelectedDistrict}
                selectedDistrict={selectedDistrict}
                setSelectedTehsil={setSelectedTehsil}
                selectedTehsil={selectedTehsil}
                setSelectedUnionCouncil={setSelectedUnionCouncil}
                selectedUnionCouncil={selectedUnionCouncil}
            />
            <div className='ResourceMapping-table-cont'>
                <div className='ResourceMapping-table-filter-btn-cont'>
                    <div className='admin-add-data-btn' onClick={() => navigate("/form", { state: { formHeading: 'event' } })}>
                        <img src={PlusIcon} alt="" />
                        <span>Add New Data</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                        <SearchSelect
                            onChange={env => { setSelectedParameter(env); setSelectedKeyword(''); }}
                            placeholder="Select the Parameter"
                            className="filter-input select-param-input"
                            value={selectedParameter || undefined}
                            options={eventColumns.map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))}
                            allowClear={true}
                        />
                        <Input
                            placeholder="Keyword Search"
                            className=' filter-input select-param-input'
                            allowClear={true}
                            disabled={!selectedParameter}
                            onChange={({ target }) => setSelectedKeyword(target.value)}
                            value={selectedKeyword}
                        />
                        <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                            <span>Show:</span>
                            <SearchSelect
                                onChange={(value: String) => setRowsPerPage(+value)}
                                placeholder="Select the District"
                                className="w-100px"
                                value={rowsPerPage}
                                options={[
                                    { value: "2", label: "2 Entries" },
                                    { value: "5", label: "5 Entries" },
                                    { value: "8", label: "8 Entries" },
                                    { value: "10", label: "10 Entries" },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div id="BottomTabs">
                    <ReusableTable
                        data={(selectedParameter && selectedKeyword) ? eventData.filter((item: any) => {
                            const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                            console.log('value', value);

                            return value && value.toLowerCase().includes(selectedKeyword.toLowerCase());
                        }) : eventData}
                        columns={eventColumns}
                        rowsPerPage={rowsPerPage} />
                </div>
            </div>
        </div>
    );
};

export default Events;

const alignEventData = (data: any, selectedDivision: String, selectedDistrict: String, selectedTehsil: String, selectedUnionCouncil: String) => {
    const alignedUserData = data?.filter((item: any) =>
        (selectedDivision ? item?.attributes?.divisions?.data.find((division: any) => division?.attributes?.division_name === selectedDivision) : true) &&
        (selectedDistrict ? item?.attributes?.districts?.data.find((district: any) => district?.attributes?.district_name === selectedDistrict) : true) &&
        (selectedTehsil ? item?.attributes?.tehsils?.data.find((tehsil: any) => tehsil?.attributes?.tehsil_name === selectedTehsil) : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_councils?.data.find((union_council: any) => union_council?.attributes?.union_council_name === selectedUnionCouncil) : true)
    ).map((item: any, index: any) => ({
        key: index + 1,
        id: item.id,
        name: item?.attributes?.name,
        description: item?.attributes?.description,
        from_date: item?.attributes?.from_date,
        to_date: item?.attributes?.to_date,
        divisions: item?.attributes?.divisions?.data.map((division: any) => (division?.attributes?.division_name)),
        districts: item?.attributes?.districts?.data.map((district: any) => (district?.attributes?.district_name)),
        tehsils: item?.attributes?.tehsils?.data.map((tehsil: any) => (tehsil?.attributes?.tehsil_name)),
        union_councils: item?.attributes?.union_councils?.data.map((union_council: any) => (union_council?.attributes?.union_council_name)),
        type: 'Users'
    })) || [];
    return alignedUserData;
};

