import React from "react";
import './index.css';
import ICON from '../../assets/images/output-onlinegiftools.gif';
import { useSelector } from "react-redux";
import { RootState } from "../../reduxToolkit/store";

const Loading = ({ isLoading }: any) => {
    const { loading } = useSelector((state: RootState) => state.users);
    const { entitiesLoading } = useSelector((state: RootState) => state.entities);
    const { importLoading } = useSelector((state: RootState) => state.imports);


    const load = isLoading || loading || entitiesLoading || importLoading;
    return (
        load ? <div className="spinner">
            <img src={ICON} alt="" />
        </div> : null
    );
};

export default Loading;