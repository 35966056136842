import React, { useEffect } from 'react';
import './index.css'; // Import CSS for styling
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, } from 'react-redux';
import { fetchAllDistrictData, fetchAllTehsilData, fetchAllUnionCouncilData, fetchDepartmentsData } from '../../reduxToolkit/entitiesSlice';
import UserForm from './Users';
import { fetchRolesData, fetchUserTypesData } from '../../reduxToolkit/userSlice';
import DistrictForm from './district';
import TehsilForm from './tehsil';
import UnionCouncilForm from './unionCouncil';
import DivisionForm from './division';
import { RootState } from '../../reduxToolkit/store';
import EventForm from './event';

const Forms = () => {
    const location = useLocation();
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const { userData } = useSelector((state: RootState) => state.auth);
    const { formHeading } = location.state || {};

    useEffect(() => {
        if (userData.id) {
            dispatch(fetchAllDistrictData());
            dispatch(fetchAllTehsilData());
            dispatch(fetchAllUnionCouncilData());
            dispatch(fetchUserTypesData());
            dispatch(fetchRolesData(userData));
            dispatch(fetchDepartmentsData(userData?.user_type?.type));
        }
    }, [dispatch, userData]);

    useEffect(() => {
        if (userData.confirmed && formHeading !== 'Users') {
            navigate("/");
        }
    }, [userData]);
    console.log('formHeading', formHeading);

    return (<div>
        {formHeading === 'Users' ? <UserForm />
            : formHeading === 'Division' ? <DivisionForm />
                : formHeading === 'District' ? <DistrictForm />
                    : formHeading === 'Tehsil' ? <TehsilForm />
                        : formHeading === 'Union Council' ? <UnionCouncilForm />
                            : formHeading === 'event' ? <EventForm />
                                : ""}
    </div>);
};

export default Forms;
