import { gql } from "@apollo/client";

export const createDivision = gql`
mutation createDivision($division_name: String) {
  createDivision(data: { division_name: $division_name }) {
    data {
      id
      attributes {
        division_name
      }
    }
  }
}
`;

export const createDistrict = gql`
mutation createDistrict($district_name: String, $divisionId: ID) {
  createDistrict(
    data: { district_name: $district_name, division: $divisionId }
  ) {
    data {
      id
      attributes {
        district_name
      }
    }
  }
}
`;

export const createTehsil = gql`
mutation createTehsil($tehsil_name: String, $divisionId: ID, $districtId: ID) {
  createTehsil(
    data: {
      division: $divisionId
      district: $districtId
      tehsil_name: $tehsil_name
    }
  ) {
    data {
      id
      attributes {
        tehsil_name
      }
    }
  }
}
`;

export const createUnionCouncil = gql`
mutation createUnionCouncil(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_council_name: String
) {
  createUnionCouncil(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council_name: $union_council_name
    }
  ) {
    data {
      id
      attributes {
        union_council_name
      }
    }
  }
}
`;

export const createEvent = gql`
mutation createEvent(
  $divisionsId: [ID]
  $districtsId: [ID]
  $tehsilsId: [ID]
  $union_councilsId: [ID]
  $name: String
  $description: String
  $from_date: Date
  $to_date: Date
) {
  createEvent(
    data: {
      name: $name
      description: $description
      from_date: $from_date
      to_date: $to_date
      divisions: $divisionsId
      districts: $districtsId
      tehsils: $tehsilsId
      union_councils: $union_councilsId
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;


export const createNgo = gql`
mutation createNewNgo(
    $ngo_name: String
    $divisionId: ID
    $districtId: ID
    $tehsilId: ID
    $union_councilId: ID
    $postal_address: String
    $primary_areasId: [ID]
    $secondary_areasId: [ID]
    $website: String
    $address: String
    $license_registration_number: String
    $year_established: String
    $organizational_head: String
    $ngo_contact_person_in_district: String
    $ngo_contact_person_mobile_number_in_district: Long
    $ngo_contact_number: Long
    $latitude: Float
    $longitude: Float
    $stage: Int
  ) {
    createNgo(
      data: {
        ngo_name: $ngo_name
        division: $divisionId
        district: $districtId
        tehsil: $tehsilId
        union_council: $union_councilId
        postal_address: $postal_address
        primary_areas: $primary_areasId
        secondary_areas: $secondary_areasId
        website: $website
        address: $address
        license_registration_number: $license_registration_number
        year_established: $year_established
        organizational_head: $organizational_head
        ngo_contact_person_in_district: $ngo_contact_person_in_district
        ngo_contact_person_mobile_number_in_district: $ngo_contact_person_mobile_number_in_district
        ngo_contact_number: $ngo_contact_number
        latitude: $latitude
        longitude: $longitude
        stage: $stage
      }
    ) {
      data {
        id
        attributes {
          ngo_name
        }
      }
    }
  }
`;

export const createVolunteer = gql`
mutation createNewVolunteer(
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $cnic_number: Long
  $address: String
  $postal_address: String
  $website: String
  $contact_person: String
  $contact_person_mobile: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createVolunteer(
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      cnic_number: $cnic_number
      address: $address
      postal_address: $postal_address
      website: $website
      contact_person: $contact_person
      contact_person_mobile: $contact_person_mobile
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const createManufacturer = gql`
mutation createNewManufacturer(
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $postal_address: String
  $primary_areasId: [ID]
  $secondary_areasId: [ID]
  $website: String
  $address: String
  $registration_number: String
  $year_established: String
  $organizational_head: String
  $contact_person_in_district: String
  $contact_person_mobile_number_in_district: Long
  $contact_number: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createManufacturer(
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      postal_address: $postal_address
      primary_areas: $primary_areasId
      secondary_areas: $secondary_areasId
      website: $website
      address: $address
      registration_number: $registration_number
      year_established: $year_established
      organizational_head: $organizational_head
      contact_person_in_district: $contact_person_in_district
      contact_person_mobile_number_in_district: $contact_person_mobile_number_in_district
      contact_number: $contact_number
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const createSupplier = gql`
mutation createNewSupplier(
  $name: String
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $postal_address: String
  $primary_areasId: [ID]
  $secondary_areasId: [ID]
  $website: String
  $address: String
  $registration_number: String
  $year_established: String
  $organizational_head: String
  $contact_person_in_district: String
  $contact_person_mobile_number_in_district: Long
  $contact_number: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createSupplier(
    data: {
      name: $name
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      postal_address: $postal_address
      primary_areas: $primary_areasId
      secondary_areas: $secondary_areasId
      website: $website
      address: $address
      registration_number: $registration_number
      year_established: $year_established
      organizational_head: $organizational_head
      contact_person_in_district: $contact_person_in_district
      contact_person_mobile_number_in_district: $contact_person_mobile_number_in_district
      contact_number: $contact_number
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const createSchool = gql`
mutation createNewSchool(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $semis_code: Long
  $name: String
  $address: String
  $latitude: Float
  $longitude: Float
  $building_condition: String
  $area_type: String
  $number_of_rooms: Int
  $electricity: Boolean
  $toilet: Boolean
  $drinking_water: Boolean
  $boundary_wall: Boolean
) {
  createSchool(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      semis_code: $semis_code
      name: $name
      address: $address
      latitude: $latitude
      longitude: $longitude
      building_condition: $building_condition
      area_type: $area_type
      number_of_rooms: $number_of_rooms
      electricity: $electricity
      toilet: $toilet
      drinking_water: $drinking_water
      boundary_wall: $boundary_wall
    }
  ) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;

export const createInventory = gql`
mutation createNewInventory(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $product_typeId: ID
  $product_nameId: ID
  $classId: ID
  $product_categoryId: ID
  $product_section: String
  $received_quantity: Long
  $consumed_quantity: Long
  $warehouse_address: String
  $description: String
  $unit_of_measure: String
  $product_code: String
  $product_barcode: String
  $product_qr_code: String
  $product_brand: String
  $product_company: String
  $induction_date: Date
  $warranty_year: String
  $expiry_date: Date
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createInventory(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      product_type: $product_typeId
      product_name: $product_nameId
      class: $classId
      product_category: $product_categoryId
      product_section: $product_section
      received_quantity: $received_quantity
      consumed_quantity: $consumed_quantity
      warehouse_address: $warehouse_address
      description: $description
      unit_of_measure: $unit_of_measure
      product_code: $product_code
      product_barcode: $product_barcode
      product_qr_code: $product_qr_code
      product_brand: $product_brand
      product_company: $product_company
      induction_date: $induction_date
      warranty_year: $warranty_year
      expiry_date: $expiry_date
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        product_code
      }
    }
  }
}

`;

export const createHumanCasualtie = gql`
mutation createNewHumanCasualtie(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $eventId: ID
  $casualty_typeId: ID
  $quantity: Long
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createHumanCasualtie(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      event: $eventId
      casualty_type: $casualty_typeId
      quantity: $quantity
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
    }
  }
}

`;

export const createInfrastructureDamage = gql`
mutation createNewInfrastructureDamage(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $address: String
  $damaged: String
  $restored: String
  $infrastructure_typeId: ID
  $eventId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createInfrastructureDamage(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      address: $address
      damaged: $damaged
      restored: $restored
      infrastructure_type: $infrastructure_typeId
      event: $eventId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        damaged
      }
    }
  }
}

`;

export const createFoodDamage = gql`
mutation createNewFoodDamage(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $address: String
  $acre: String
  $cost: Long
  $agriculture_typeId: ID
  $eventId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createFoodDamage(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      address: $address
      acre: $acre
      cost: $cost
      agriculture_type: $agriculture_typeId
      event: $eventId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        acre
      }
    }
  }
}

`;

export const createLivestockDamage = gql`
mutation createNewLivestockDamage(
  $divisionId: ID
  $districtId: ID
  $tehsilId: ID
  $union_councilId: ID
  $total_quantity: Long
  $address: String
  $cost: Long
  $livestock_typeId: ID
  $eventId: ID
  $casualty_typeId: ID
  $latitude: Float
  $longitude: Float
  $stage: Int
) {
  createLivestockDamage(
    data: {
      division: $divisionId
      district: $districtId
      tehsil: $tehsilId
      union_council: $union_councilId
      total_quantity: $total_quantity
      address: $address
      cost: $cost
      livestock_type: $livestock_typeId
      event: $eventId
      casualty_type: $casualty_typeId
      latitude: $latitude
      longitude: $longitude
      stage: $stage
    }
  ) {
    data {
      id
      attributes {
        total_quantity
      }
    }
  }
}
`;