// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import usersSlice from './userSlice';
import entitiesSlice from './entitiesSlice';
import importSlice from './importSlice';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        entities: entitiesSlice,
        users: usersSlice,
        imports: importSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
