import React, { useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import { Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from '../../components/Toast';
import hierarchy_icon from "../../assets/images/hierarchy_icon.png";
import { createDivisionData } from '../../reduxToolkit/entitiesSlice';
import './index.css'; // Import CSS for styling

const DivisionForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const [newData, setNewData] = useState<{ [key: string]: any; }>({});
    const [isCreating, setIsCreating] = useState(false);

    const onSave = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            const obj: any = {
                "division_name": newData.division_name,
            };
            if (Object.values(obj).some(value => !value)) {
                throw new Error('Division are required');
            }
            const res = await dispatch(createDivisionData(obj));
            if (res.type !== "entities/createDivisionData/rejected") {
                navigate(-1);
                toast('success', "Division create successfully");
            } else {
                setIsCreating(false);
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={hierarchy_icon} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>Hierarchy</span>
                        </div>
                    </div>
                    <div className='form-send-btn' onClick={onSave}>
                        <span className='sand-btn-text'>Save Information</span>
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-bady-heading'>
                    <span>Create Division</span>
                </div>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Input
                            placeholder="Enter Division Name"
                            onChange={({ target }) => setNewData(prevState => ({ ...prevState, division_name: target.value }))}
                            value={newData.division_name}
                            // style={{ width: '25%' }}
                            className='input' />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default DivisionForm;
