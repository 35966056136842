// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-cont {
  height: 88px;
  /* Adjust height as needed */
  width: 100%;
  /* Full screen width */
  background-size: cover;
  /* Ensure the background image covers the entire footer-cont */
  background-position: center;
  /* Center the background image */
  /* Add any additional styles here */
  background-color: #073042;
  padding-bottom: 3px;
  /* transform: translateY(257%); */
}

.footer {
  width: 92%;
  height: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid white; */
}

@media only screen and (max-width: 1300px) {
  .footer-cont {
    height: 88px;
  }
}

@media only screen and (max-width: 1250px) {
  .footer-cont {
    height: 88x;
  }
}

@media only screen and (max-width: 1200px) {
  .footer-cont {
    height: 88px;
  }
}

@media only screen and (max-width: 1150px) {
  .footer-cont {
    height: 88px;
  }
}

@media only screen and (max-width: 1100px) {
  .footer-cont {
    height: 88px;
  }
}

@media only screen and (max-width: 1050px) {
  .footer-cont {
    height: 88px;
  }
}

@media only screen and (max-width: 1000px) {
  .footer-cont {
    height: 88px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,4BAA4B;EAC5B,WAAW;EACX,sBAAsB;EACtB,sBAAsB;EACtB,8DAA8D;EAC9D,2BAA2B;EAC3B,gCAAgC;EAChC,mCAAmC;EACnC,yBAAyB;EACzB,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".footer-cont {\n  height: 88px;\n  /* Adjust height as needed */\n  width: 100%;\n  /* Full screen width */\n  background-size: cover;\n  /* Ensure the background image covers the entire footer-cont */\n  background-position: center;\n  /* Center the background image */\n  /* Add any additional styles here */\n  background-color: #073042;\n  padding-bottom: 3px;\n  /* transform: translateY(257%); */\n}\n\n.footer {\n  width: 92%;\n  height: 100%;\n  margin: 0px auto;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  /* border: 1px solid white; */\n}\n\n@media only screen and (max-width: 1300px) {\n  .footer-cont {\n    height: 88px;\n  }\n}\n\n@media only screen and (max-width: 1250px) {\n  .footer-cont {\n    height: 88x;\n  }\n}\n\n@media only screen and (max-width: 1200px) {\n  .footer-cont {\n    height: 88px;\n  }\n}\n\n@media only screen and (max-width: 1150px) {\n  .footer-cont {\n    height: 88px;\n  }\n}\n\n@media only screen and (max-width: 1100px) {\n  .footer-cont {\n    height: 88px;\n  }\n}\n\n@media only screen and (max-width: 1050px) {\n  .footer-cont {\n    height: 88px;\n  }\n}\n\n@media only screen and (max-width: 1000px) {\n  .footer-cont {\n    height: 88px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
