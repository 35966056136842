import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from "../graphql";
import client from "../helpers";
import { RootState } from "./store";

interface UsersState {
    importLoading: boolean;
}

const initialState: UsersState = {
    importLoading: false,
};

export const createNGOData = createAsyncThunk('imports/createNGOData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateNGO,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createNgo.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createVolunteerData = createAsyncThunk('imports/createVolunteerData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateVolunteer,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createVolunteer.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createManufacturerData = createAsyncThunk('imports/createManufacturerData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateManufacturer,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createManufacturer.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createSupplierData = createAsyncThunk('imports/createSupplierData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateSupplier,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createSupplier.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createSchoolData = createAsyncThunk('imports/createSchoolData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateSchool,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createSchool.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createInventoryData = createAsyncThunk('imports/createInventoryData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateInventory,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createInventory.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createHumanCasualtieData = createAsyncThunk('imports/createHumanCasualtieData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateHumanCasualtie,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createHumanCasualtie.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createInfrastructureDamageData = createAsyncThunk('imports/createInfrastructureDamageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateInfrastructureDamage,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createInfrastructureDamage.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createFoodDamageData = createAsyncThunk('imports/createFoodDamageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateFoodDamage,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createFoodDamage.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createLivestockDamageData = createAsyncThunk('imports/createLivestockDamageData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            Authorization: `Bearer ${process.env.REACT_APP_ADMIN_TOKEN}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateLivestockDamage,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createLivestockDamage.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const importSlice = createSlice({
    name: 'imports',
    initialState,
    reducers: {},
    extraReducers(builder) {
        [
            createNGOData,
            createVolunteerData,
            createManufacturerData,
            createSupplierData,
            createSchoolData,
            createInventoryData,
            createHumanCasualtieData,
            createInfrastructureDamageData,
            createFoodDamageData,
            createLivestockDamageData
        ].forEach(action => {
            builder
                .addCase(action.fulfilled, (state) => {
                    state.importLoading = false;
                });
        });
        [
            createNGOData,
            createVolunteerData,
            createManufacturerData,
            createSupplierData,
            createSchoolData,
            createInventoryData,
            createHumanCasualtieData,
            createInfrastructureDamageData,
            createFoodDamageData,
            createLivestockDamageData
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.importLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.importLoading = false;
                });
        });

    }
});


export default importSlice.reducer;
