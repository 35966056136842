import { message } from 'antd';

export const toast = (type, msg, duration = 4.5) => {
    const value = msg?.includes("Forbidden access")
        ? "You don't have permission to do this."
        : msg?.includes("Failed to fetch")
            ? "Please check your internet connection."
            : msg;

    message[type](value, duration);
};
