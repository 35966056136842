import React, { useEffect, useState } from 'react';
import PlusIcon from '../../assets/images/plusIcon.png';
import ReusableTable from '../../components/table';
import SearchSelect from '../../components/selectDropdown';
import Filters from '../../components/top_filter';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Input, MenuProps, Tabs } from 'antd';
import './index.css';
import { RootState } from '../../reduxToolkit/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllDistrictData, fetchAllTehsilData, fetchAllUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { useNavigate } from 'react-router-dom';
import { districtColumns, divisionColumns, tehsilColumns, unionCouncilColumns } from '../../helpers/tableColumns';

const Hierarchy: React.FC = () => {
    const { divisionList, allDistrictList, alltehsilList, allUnionCouncilList } = useSelector((state: RootState) => state.entities);
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedTehsil, setSelectedTehsil] = useState('');
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState('');
    const [selectedParameter, setSelectedParameter] = useState('');
    const [selectedKeyword, setSelectedKeyword] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedTab, setSelectedTab] = useState('1');

    useEffect(() => {
        dispatch(fetchAllDistrictData());
        dispatch(fetchAllTehsilData());
        dispatch(fetchAllUnionCouncilData());
    }, [dispatch]);

    const onChangeTabs = (activeKey: string) => {
        setSelectedTab(activeKey);
    };

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        const formHeading = e.key === "1" ? 'Division' : e.key === "2" ? 'District' : e.key === "3" ? 'Tehsil' : e.key === "4" ? 'Union Council' : '';
        navigate("/form", { state: { formHeading } });
    };

    const items: MenuProps['items'] = [
        {
            label: 'Division',
            key: '1',
        },
        {
            label: 'District',
            key: '2',
        },
        {
            label: 'Tehsil',
            key: '3',
        },
        {
            label: 'Union Council',
            key: '4',
        }
    ];

    const divisionData = alignDivisionData(divisionList, selectedDivision);
    const districtData = alignDistrictData(allDistrictList, selectedDivision, selectedDistrict);
    const tehsilData = alignTehsilData(alltehsilList, selectedDivision, selectedDistrict, selectedTehsil);
    const unionCouncilData = alignUnionCouncilData(allUnionCouncilList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);

    return (
        <div className='Inventory-mainCont'>
            <Filters
                setSelectedDivision={setSelectedDivision}
                selectedDivision={selectedDivision}
                setSelectedDistrict={setSelectedDistrict}
                selectedDistrict={selectedDistrict}
                setSelectedTehsil={setSelectedTehsil}
                selectedTehsil={selectedTehsil}
                setSelectedUnionCouncil={setSelectedUnionCouncil}
                selectedUnionCouncil={selectedUnionCouncil}
            />
            <div className='ResourceMapping-table-cont'>
                <div className='ResourceMapping-table-filter-btn-cont'>
                    <Dropdown menu={{ items, onClick: handleMenuClick }} placement="bottom">
                        <div className='admin-add-data-btn'>
                            <img src={PlusIcon} alt="" />
                            <span>Add New Data</span>
                            <DownOutlined />
                        </div>
                    </Dropdown>
                    <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                        <SearchSelect
                            onChange={env => { setSelectedParameter(env); setSelectedKeyword(''); }}
                            placeholder="Select the Parameter"
                            className="filter-input select-param-input"
                            value={selectedParameter || undefined}
                            options={(selectedTab === '1' ? divisionColumns : selectedTab === '2' ? districtColumns : selectedTab === '3' ? tehsilColumns : selectedTab === '4' ? unionCouncilColumns : [])
                                .map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))
                            }
                            allowClear={true}
                        />
                        <Input
                            placeholder="Keyword Search"
                            className=' filter-input select-param-input'
                            allowClear={true}
                            disabled={!selectedParameter}
                            onChange={({ target }) => setSelectedKeyword(target.value)}
                            value={selectedKeyword}
                        />
                        <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                            <span>Show:</span>
                            <SearchSelect
                                onChange={(value: String) => setRowsPerPage(+value)}
                                placeholder="Select the District"
                                className="w-100px"
                                value={rowsPerPage}
                                options={[
                                    { value: "2", label: "2 Entries" },
                                    { value: "5", label: "5 Entries" },
                                    { value: "8", label: "8 Entries" },
                                    { value: "10", label: "10 Entries" },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div id="BottomTabs">
                    <Tabs
                        defaultActiveKey="1"
                        onTabClick={(activeKey) => { onChangeTabs(activeKey); setSelectedParameter(''); setSelectedKeyword(''); }}
                        activeKey={selectedTab}
                        type='card'
                        tabBarStyle={{ color: "#8D949C" }}
                        style={{ width: "100%" }}
                        items={[
                            {
                                label: 'Division',
                                key: '1',
                                children: <ReusableTable
                                    data={(selectedParameter && selectedKeyword && selectedTab === '1') ? divisionData.filter((item: any) => {
                                        const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                        return value && value.includes(selectedKeyword.toLowerCase());
                                    }) : divisionData}
                                    columns={divisionColumns}
                                    rowsPerPage={rowsPerPage}
                                />
                            },
                            {
                                label: 'District',
                                key: '2',
                                children: <ReusableTable
                                    data={(selectedParameter && selectedKeyword && selectedTab === '2') ? districtData.filter((item: any) => {
                                        const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                        return value && value.includes(selectedKeyword.toLowerCase());
                                    }) : districtData}
                                    columns={districtColumns}
                                    rowsPerPage={rowsPerPage} />,
                            },
                            {
                                label: 'Tehsil',
                                key: '3',
                                children: <ReusableTable
                                    data={(selectedParameter && selectedKeyword && selectedTab === '3') ? tehsilData.filter((item: any) => {
                                        const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                        return value && value.includes(selectedKeyword.toLowerCase());
                                    }) : tehsilData}
                                    columns={tehsilColumns}
                                    rowsPerPage={rowsPerPage} />,
                            },
                            {
                                label: 'Union Council',
                                key: '4',
                                children: <ReusableTable
                                    data={(selectedParameter && selectedKeyword && selectedTab === '4') ? unionCouncilData.filter((item: any) => {
                                        const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                                        return value && value.includes(selectedKeyword.toLowerCase());
                                    }) : unionCouncilData}
                                    columns={unionCouncilColumns}
                                    rowsPerPage={rowsPerPage} />,
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default Hierarchy;

const alignDivisionData = (data: any, selectedDivision: String) => {
    const alignedNgoData = data?.filter((item: any) =>
        (selectedDivision ? item?.attributes?.division_name === selectedDivision : true)
    ).map((item: any, index: any) => ({
        key: index + 1,
        id: item.id,
        division: item?.attributes?.division_name,
        type: 'Division'
    })) || [];
    return alignedNgoData;
};

const alignDistrictData = (data: any, selectedDivision: String, selectedDistrict: String) => {
    const alignedNgoData = data?.filter((item: any) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district_name === selectedDistrict : true)
    ).map((item: any, index: any) => ({
        key: index + 1,
        id: item.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        district: item?.attributes?.district_name,
        type: 'District'
    })) || [];
    return alignedNgoData;
};

const alignTehsilData = (data: any, selectedDivision: String, selectedDistrict: String, selectedTehsil: String) => {
    const alignedNgoData = data?.filter((item: any) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil_name === selectedTehsil : true)
    ).map((item: any, index: any) => ({
        key: index + 1,
        id: item.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsil: item?.attributes?.tehsil_name,
        type: 'Tehsil'
    })) || [];
    return alignedNgoData;
};

const alignUnionCouncilData = (data: any, selectedDivision: String, selectedDistrict: String, selectedTehsil: String, selectedUnionCouncil: String) => {
    const alignedNgoData = data?.filter((item: any) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item: any, index: any) => ({
        key: index + 1,
        id: item.id,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_council: item?.attributes?.union_council_name,
        type: 'Union Council'
    })) || [];
    return alignedNgoData;
};
