import { createDistrict, createDivision, createEvent, createFoodDamage, createHumanCasualtie, createInfrastructureDamage, createInventory, createLivestockDamage, createManufacturer, createNgo, createSchool, createSupplier, createTehsil, createUnionCouncil, createVolunteer } from "./mutation";
import { getAllDistrict, getAllTehsils, getRoles, getAllUnionCouncils, getDistrict, getDivision, getTehsils, getUnionCouncils, getUsers, getDepartments, getAllUserTypes, getUserById, getUnionCouncilById, getTehsilById, getDistrictById, getDivisionById, getEvents, getEventById, getAllPrimaryAreas, getAllPrimaryAreaById, getAllSecondaryAreas, getAllSecondaryAreaById, getProductTypes, getProductTypeById, getProductNames, getProductNameById, getProductCategories, getProductCategoryById, getAllClasses, getCasualtyTypes, getCasualtyTypeById, getInfrastructureTypes, getInfrastructureTypeById, getAgricultureTypes, getAgricultureTypeById, getLivestockTypes, getLivestockTypeById } from "./queries";

const API = {
  ApiDivision: getDivision,
  ApiGetDivisionById: getDivisionById,

  ApiDistrict: getDistrict,
  ApiAllDistrict: getAllDistrict,
  ApiGetDistrictById: getDistrictById,

  ApiTehsils: getTehsils,
  ApiAllTehsils: getAllTehsils,
  ApiGetTehsilById: getTehsilById,

  ApiUnionCouncils: getUnionCouncils,
  ApiAllUnionCouncils: getAllUnionCouncils,
  ApiGetUnionCouncilById: getUnionCouncilById,

  ApiUsers: getUsers,
  ApiUserById: getUserById,
  ApiRoles: getRoles,
  ApiDepartments: getDepartments,
  ApiUserTypes: getAllUserTypes,

  ApiEvents: getEvents,
  ApiGetEventById: getEventById,

  // mutation

  ApiCreateDivision: createDivision,
  ApiCreateDistrict: createDistrict,
  ApiCreateTehsil: createTehsil,
  ApiCreateUnionCouncil: createUnionCouncil,
  ApiCreateEvent: createEvent,

  ApiAllPrimaryAreas: getAllPrimaryAreas,
  ApiAllPrimaryAreaById: getAllPrimaryAreaById,
  ApiAllSecondaryAreas: getAllSecondaryAreas,
  ApiAllSecondaryAreaById: getAllSecondaryAreaById,
  ApiProductTypes: getProductTypes,
  ApiGetProductTypeById: getProductTypeById,
  ApiProductNames: getProductNames,
  ApiGetProductNameById: getProductNameById,
  ApiProductCategories: getProductCategories,
  ApiGetProductCategoryById: getProductCategoryById,
  ApiGetAllClasses: getAllClasses,
  ApiCasualtyTypes: getCasualtyTypes,
  ApiGetCasualtyTypeById: getCasualtyTypeById,
  ApiInfrastructureTypes: getInfrastructureTypes,
  ApiGetInfrastructureTypeById: getInfrastructureTypeById,
  ApiAgricultureTypes: getAgricultureTypes,
  ApiGetAgricultureTypeById: getAgricultureTypeById,
  ApiGetLivestockTypes: getLivestockTypes,
  ApiGetLivestockTypeById: getLivestockTypeById,

  ApiCreateNGO: createNgo,
  ApiCreateVolunteer: createVolunteer,
  ApiCreateManufacturer: createManufacturer,
  ApiCreateSupplier: createSupplier,
  ApiCreateSchool: createSchool,
  ApiCreateInventory: createInventory,
  ApiCreateHumanCasualtie: createHumanCasualtie,
  ApiCreateInfrastructureDamage: createInfrastructureDamage,
  ApiCreateFoodDamage: createFoodDamage,
  ApiCreateLivestockDamage: createLivestockDamage,
};

export default API;