import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";
const REACT_APP_ADMIN_TOKEN = process.env.REACT_APP_ADMIN_TOKEN;

interface UsersState {
    usersList: any[];
    loading: boolean;
    rolesList: any[];
    userTypeList: any[];
}

const initialState: UsersState = {
    usersList: [],
    loading: false,
    rolesList: [],
    userTypeList: [],
};

export const fetchUsersData = createAsyncThunk('users/fetchUsersData', async (user: any, { rejectWithValue, getState }) => {
    try {
        const loginType = localStorage.getItem('loginType');
        const authToken = (getState() as RootState).auth.token;
        const token = loginType === 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        let variables: any = {
            "filter": {
                role: {
                    name: {
                        notIn: ["Public", "Authenticated"]
                    }
                }
            }
        };
        if (!user.isSubperAdmin) {
            variables.filter.district = {
                district_name: {
                    eq: user?.district?.district_name
                }
            };
        }
        const response = await client.query({
            query: API.ApiUsers,
            context: {
                headers,
            },
            variables
        });
        return response.data.usersPermissionsUsers.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchRolesData = createAsyncThunk('users/fetchRolesData', async (user: any, { rejectWithValue, getState }) => {
    try {
        const loginType = localStorage.getItem('loginType');
        const authToken = (getState() as RootState).auth.token;
        const token = loginType === 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        let variables: any = {
            "filter": {
                name: {
                    notIn: ["Public", "Authenticated"]
                }
            }
        };
        if (!user.isSubperAdmin) {
            variables.filter.name = {
                notIn: ["Public", "Authenticated", "District Administrator"]
            };
        }
        const response = await client.query({
            query: API.ApiRoles,
            context: {
                headers,
            },
            variables
        });
        return response.data.usersPermissionsRoles.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

// export const createUserData = createAsyncThunk('users/createUserData', async (data: any, { rejectWithValue, getState }) => {
//     try {
//         const loginType = localStorage.getItem('loginType');
//         const authToken = (getState() as RootState).auth.token;
//         const token = loginType !== 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
//         const headers = {
//             Authorization: `Bearer ${authToken}`,
//             "Content-Type": "application/json"
//         };
//         const backendDevUrl = process.env.REACT_APP_LOGIN_DEV_URL;
//         const response = await fetch(`${backendDevUrl}content-manager/collection-types/plugin::users-permissions.user`, {
//             method: "POST",
//             headers: headers,
//             body: JSON.stringify(data)
//         });
//         const res = await response.json();
//         if (!res || res.error) {
//             throw new Error(res.error.message);
//         }
//         return res;
//     } catch (error: any) {
//         toast('error', error.message);
//         return rejectWithValue(error);
//     }
// });

export const createUserData = createAsyncThunk('users/createUserData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const headers = {
            // Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json"
        };
        const backendDevUrl = process.env.REACT_APP_LOGIN_DEV_URL;
        const response = await fetch(`${backendDevUrl}api/auth/local/register`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        });
        const res = await response.json();
        if (!res || res.error) {
            throw new Error(res.error.message);
        }
        const updateUser = await fetch(`${backendDevUrl}api/users/${res.user.id}`, {
            method: "PUT",
            headers: { ...headers, Authorization: `Bearer ${res.jwt}` },
            body: JSON.stringify(data)
        });
        const updateUserRes = await updateUser.json();
        return updateUserRes;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchUserTypesData = createAsyncThunk('users/fetchUserTypesData', async (_, { rejectWithValue, getState }) => {
    try {
        const loginType = localStorage.getItem('loginType');
        const authToken = (getState() as RootState).auth.token;
        const token = loginType === 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const response = await client.query({
            query: API.ApiUserTypes,
            context: {
                headers,
            },
        });
        return response.data.userTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateUserRecordFromSocket = createAsyncThunk('users/addAndUpdateUserRecordFromSocket', async (data: any, { rejectWithValue, getState }) => {
    try {
        const { recordId, userData } = data;
        const loginType = localStorage.getItem('loginType');
        const authToken = (getState() as RootState).auth.token;
        const token = loginType === 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const response = await client.query({
            query: API.ApiUserById,
            context: { headers },
            variables: { id: recordId },
            fetchPolicy: 'no-cache'
        });
        return { data: response.data.usersPermissionsUser.data, userData };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteUserRecordFromSocket = createAsyncThunk('users/deleteUserRecordFromSocket', async (recordId: any, { rejectWithValue, getState }) => {
    try {
        return recordId;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchUsersData.pending, (state, actions) => {
                state.loading = true;
            })
            .addCase(fetchUsersData.fulfilled, (state, action) => {
                state.usersList = action.payload;
                state.loading = false;
            })
            .addCase(fetchUsersData.rejected, (state, actions) => {
                state.usersList = [];
                state.loading = false;
            })
            .addCase(fetchRolesData.pending, (state, actions) => {
                state.loading = true;
            })
            .addCase(fetchRolesData.fulfilled, (state, action) => {
                state.rolesList = action.payload;
                state.loading = false;
            })
            .addCase(fetchRolesData.rejected, (state, actions) => {
                state.loading = false;
            })
            .addCase(fetchUserTypesData.pending, (state, actions) => {
                state.loading = true;
            })
            .addCase(fetchUserTypesData.fulfilled, (state, action) => {
                state.userTypeList = action.payload;
                state.loading = false;
            })
            .addCase(fetchUserTypesData.rejected, (state, actions) => {
                state.loading = false;
            })
            .addCase(createUserData.pending, (state, actions) => {
                state.loading = true;
            })
            .addCase(createUserData.fulfilled, (state, action) => {
                // state.usersList.push(action.payload);
                state.loading = false;
            })
            .addCase(createUserData.rejected, (state, actions) => {
                state.loading = false;
            })
            .addCase(addAndUpdateUserRecordFromSocket.fulfilled, (state, action) => {
                const { data, userData } = action.payload;
                const { id, attributes } = data;
                if (userData.isSubperAdmin || (attributes?.district?.data?.attributes?.district_name === userData?.district?.district_name)) {
                    const index = state.usersList.findIndex((item: any) => item.id == id);
                    const updatedArray = [...state.usersList];
                    index !== -1 ? updatedArray[index] = data : updatedArray.push(data);
                    state.usersList = updatedArray;
                } else {
                    state.usersList = [...state.usersList.filter((item: any) => item.id != data.id)];
                }
            })
            .addCase(deleteUserRecordFromSocket.fulfilled, (state, action) => {
                state.usersList = state.usersList.filter(item => item.id != action.payload);
            });

    }
});


export default usersSlice.reducer;
