import React from 'react';
import { Select } from 'antd';

interface SearchSelectProps {
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  filterOption?: (input: string, option?: { label: string; value: string; }) => boolean;
  placeholder?: string;
  options: { value: string; label: string; }[];
  className?: string;
  mode?: any;
  allowClear?: any;
  showSearch?: any;
  value?: any;
}

const SearchSelect: React.FC<SearchSelectProps> = ({
  onChange,
  onSearch,
  filterOption,
  placeholder = 'Select a person',
  options,
  className,
  mode = undefined,
  allowClear = false,
  showSearch = false,
  value = [],
}) => (
  <Select
    showSearch={showSearch}
    mode={mode}
    allowClear={allowClear}
    value={value}
    placeholder={placeholder}
    optionFilterProp="children"
    onChange={onChange}
    onSearch={onSearch}
    filterOption={filterOption}
    className={className}
    options={options}
  />
);

export default SearchSelect;
