// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    isAuthenticated: boolean;
    editorMode: boolean; // 'public' or 'district'
    token: string;
    userData: any;
    loading: boolean;
}

const initialState: AuthState = {
    isAuthenticated: false,
    editorMode: false,
    token: '',
    userData: {},
    loading: false,
};

const loadInitialState = () => {
    const token = localStorage.getItem('token');
    const editorMode = localStorage.getItem('editorMode');
    if (token) {
        return { loading: false, isAuthenticated: true, editorMode: !!editorMode && editorMode === "view" ? false : true, token, userData: {} };
    } else {
        return initialState;
    }
};

const authSlice = createSlice({
    name: 'auth',
    initialState: loadInitialState(), // Load initial state from browser storage
    reducers: {
        login: (state, action: PayloadAction<{ jwt: string, user: any; }>) => {
            const { jwt, user } = action.payload;
            state.isAuthenticated = true;
            state.token = jwt;
            state.userData = { ...user, editorAccess: true };
            state.editorMode = false;
            // Save token to browser storage
            localStorage.setItem('token', jwt);
            localStorage.setItem('editorMode', 'view');
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.token = '';
            state.userData = {};
            state.editorMode = false;
            // Clear token from browser storage
            localStorage.removeItem('token');
            localStorage.removeItem('editorMode');
        },
        changeUserRoll: (state, action: PayloadAction<string>) => {
            // localStorage.removeItem('editorMode');
            state.editorMode = action.payload === "view" ? false : true;
            localStorage.setItem('editorMode', action.payload);
        },
        loadUserFromToken: (state, action: PayloadAction<{ user: any; }>) => {
            const { user } = action.payload;
            state.isAuthenticated = true;
            state.userData = { ...user, editorAccess: true };
            // state.editorMode = false;
        }
    },
});


export const { login, logout, changeUserRoll, loadUserFromToken } = authSlice.actions;

export default authSlice.reducer;
