// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
  height: 222px;
  /* Adjust height as needed */
  width: 100%;
  /* Full screen width */
  background-size: cover;
  /* Ensure the background image covers the entire banner */
  background-position: center;
  /* Center the background image */
  /* Add any additional styles here */
}

.banner > img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1300px) {
  .banner {
    height: 275px;
  }
}

@media only screen and (max-width: 1250px) {
  .banner {
    height: 250px;
  }
}

@media only screen and (max-width: 1200px) {
  .banner {
    height: 240px;
  }
}

@media only screen and (max-width: 1150px) {
  .banner {
    height: 230px;
  }
}

@media only screen and (max-width: 1100px) {
  .banner {
    height: 220px;
  }
}

@media only screen and (max-width: 1050px) {
  .banner {
    height: 210px;
  }
}

@media only screen and (max-width: 1000px) {
  .banner {
    height: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Banner/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4BAA4B;EAC5B,WAAW;EACX,sBAAsB;EACtB,sBAAsB;EACtB,yDAAyD;EACzD,2BAA2B;EAC3B,gCAAgC;EAChC,mCAAmC;AACrC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".banner {\n  height: 222px;\n  /* Adjust height as needed */\n  width: 100%;\n  /* Full screen width */\n  background-size: cover;\n  /* Ensure the background image covers the entire banner */\n  background-position: center;\n  /* Center the background image */\n  /* Add any additional styles here */\n}\n\n.banner > img {\n  width: 100%;\n  height: 100%;\n}\n\n@media only screen and (max-width: 1300px) {\n  .banner {\n    height: 275px;\n  }\n}\n\n@media only screen and (max-width: 1250px) {\n  .banner {\n    height: 250px;\n  }\n}\n\n@media only screen and (max-width: 1200px) {\n  .banner {\n    height: 240px;\n  }\n}\n\n@media only screen and (max-width: 1150px) {\n  .banner {\n    height: 230px;\n  }\n}\n\n@media only screen and (max-width: 1100px) {\n  .banner {\n    height: 220px;\n  }\n}\n\n@media only screen and (max-width: 1050px) {\n  .banner {\n    height: 210px;\n  }\n}\n\n@media only screen and (max-width: 1000px) {\n  .banner {\n    height: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
