// Banner.tsx

import React from 'react';
import './index.css'; // Import CSS for styling
import footerLogo from "../../assets/images/footerLogo.png";
import footerIcon from "../../assets/images/FooterIcon.png";

const Footer: React.FC<{ imageUrl: string }> = ({ imageUrl }) => {
  return (
    <div className="footer-cont" style={{ backgroundImage: `url(${imageUrl})` }}>
      {/* <img src={footer} alt="Logo2" /> */}
      <div className='footer'>
        <div>
          <img src={footerLogo} alt="Logo2" style={{ height: "90px" }} />
        </div>
        <div>
          <img src={footerIcon} alt="Logo2" style={{ height: "70px" }} />
        </div>
      </div>
      {/* Add any content or additional elements inside the Footer */}
    </div>
  );
};

export default Footer;
