import React, { useEffect, useState } from 'react';
import "./index.css";
import { useDispatch, useSelector } from 'react-redux';
import { login } from "../../reduxToolkit/authSlice";
import { useNavigate } from 'react-router-dom';
import HeadingIcon from '../../assets/images/loginHeadingIcon.png';
import { IoEye, IoEyeOff, IoPerson } from "react-icons/io5";
import { toast } from '../../components/Toast';
import { Button, Switch } from 'antd';
import { RootState } from '../../reduxToolkit/store';
import { fetchUsersData } from '../../reduxToolkit/userSlice';

const Login: React.FC = () => {
    const [values, setValues] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [loginType, setLoginType] = useState(localStorage.getItem('loginType'));
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const { isAuthenticated } = useSelector((state: RootState) => state.auth);
    const loginUrl = loginType === 'Login as district admin' ? "api/auth/local" : "admin/login";
    const fetchUrl = loginType === 'Login as district admin' ? "api/users/me?populate=*" : "admin/users/me";

    const handleChange = (name: any, value: any) => {
        setValues(prevState => ({ ...prevState, [name]: value }));
        if (name === "email") {
            validateEmail(value);
        }
    };

    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(email)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/users');
        }
    }, [isAuthenticated, navigate]);

    // Function to fetch user data using token
    const fetchUserData = async (token: string) => {
        try {
            // Ensure backend URL is available
            if (!process.env.REACT_APP_LOGIN_DEV_URL) {
                throw new Error('Backend URL not provided in environment variables');
            }
            const backendDevUrl = process.env.REACT_APP_LOGIN_DEV_URL;
            const response = await fetch(`${backendDevUrl}${fetchUrl}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                const errorData = await response.json(); // Try to parse error details
                // Handle specific error messages from the server (if applicable)
                if (errorData.error) {
                    throw new Error(errorData.error.message); // Relay specific error message
                } else {
                    throw new Error(`HTTP error ${response.status}: ${response.statusText}`);
                }
            }
            const userData = await response.json();
            if (loginType === 'Login as admin') {
                return userData.data;
            }
            return userData;
        } catch (error) {
            console.error('Error fetching user data:', error);
            return Promise.reject(error); // Explicitly reject the promise
        }
    };

    const handleLogin = async () => {
        const { email, password } = values;
        if (!emailError && email && password) {
            setLoading(true);
            if (!process.env.REACT_APP_LOGIN_DEV_URL) {
                throw new Error('Backend URL not provided in environment variables');
            }
            const backendDevUrl = process.env.REACT_APP_LOGIN_DEV_URL;
            try {
                const obj: any = { identifier: email, password };
                if (loginType === 'Login as admin') {
                    delete obj.identifier;
                    obj.email = email;
                }
                const response = await fetch(`${backendDevUrl}${loginUrl}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(obj)
                });
                if (!response.ok) {
                    throw new Error("Invalid Email or Password");
                }
                const data = await response.json();
                const token = loginType === 'Login as admin' ? data.data.token : data.jwt;
                const user = await fetchUserData(token);
                const isSubperAdmin = loginType === 'Login as admin' ? true : false;
                const roles = isSubperAdmin ? user.roles : [user.role];
                if (roles.find((item: any) => item.name === "Super Admin" || item.name === "System Administrator" || item.name === "District Administrator")) {
                    dispatch(login({ jwt: token, user: { ...user, isSubperAdmin } }));
                    dispatch(fetchUsersData({ ...user, isSubperAdmin }));
                    setLoading(false);
                } else {
                    throw new Error("Invalid Email or Password");
                }
            } catch (error: any) {
                toast('error', error.message);
                setLoading(false);
            }
        };
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };
    const checked = loginType === 'Login as district admin' ? true : false;
    return (
        <div className='loginContainer'>
            <div className='headingCont'>
                <img src={HeadingIcon} alt="Logo2" />
                <span>Kindly Input Credentials to access the Portal.</span>
            </div>
            <div className='labelCont'>
                <div className='labelContInsideDiv' style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                    <div className='Login-As-Text' style={{ marginRight: '10px' }}>Login as System Administrator</div>
                    <Switch
                        onChange={value => {
                            setLoginType(value ? 'Login as district admin' : 'Login as admin');
                            localStorage.setItem("loginType", value ? 'Login as district admin' : 'Login as admin');
                        }}
                        checked={checked}
                    />
                    <div className='Login-As-Text' style={{ marginLeft: '10px' }}>Login as District Administrator</div>
                </div>
                {/* <span>This is for the Members only!</span> */}
            </div>
            <div className='inputCont'>
                <input
                    type="email"
                    placeholder='Email*'
                    value={values.email}
                    onChange={({ target }) => handleChange('email', target.value)}
                    name=""
                    autoComplete="new-password"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                />
                {emailError && <span className="error">{emailError}</span>}
                <input
                    type={showPassword ? "text" : "password"}
                    placeholder='Password'
                    value={values.password}
                    onChange={({ target }) => handleChange('password', target.value)}
                    name=""
                    autoComplete="new-password"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                />
                {showPassword ? (
                    <IoEyeOff className='eyeIcon' onClick={togglePasswordVisibility} />
                ) : (
                    <IoEye className='eyeIcon' onClick={togglePasswordVisibility} />
                )}
            </div>
            <div className='loginBtnCont'>
                <Button
                    type="primary"
                    icon={<IoPerson className='personIcon' />}
                    loading={Loading}
                    onClick={handleLogin}
                >
                    Login
                </Button>
            </div>
        </div>
    );
};

export default Login;
