import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { UserManagments, Login, Forms, Hierarchy, Events, ImportComponent } from './pages';
import { Provider } from 'react-redux';
import { store } from './reduxToolkit/store';
import { Banner, Header, Loading, Tabs } from './components';
import Footer from './components/footer';
import { useDispatch } from 'react-redux';
import { loadUserFromToken } from "./reduxToolkit/authSlice";
import { fetchDivisionData } from './reduxToolkit/entitiesSlice';
import { PrivateRoute } from './privateRoute';
import { toast } from './components/Toast';
import { fetchUsersData } from './reduxToolkit/userSlice';
import { initializeSocket } from './config/socket';


const App: React.FC = () => {
  const dispatch = useDispatch<any>();
  const loginType = localStorage.getItem('loginType');
  const fetchUrl = loginType === 'Login as district admin' ? "api/users/me?populate=*" : "admin/users/me";

  useEffect(() => {
    const token: any = localStorage.getItem('token');
    if (token) {
      fetchUserData(token)
        .then(user => {
          if (user) {
            const isSubperAdmin = loginType === 'Login as admin' ? true : false;
            const roles = isSubperAdmin ? user.roles : [user.role];
            if (roles.find((item: any) => item.name === "Super Admin" || item.name === "System Administrator" || item.name === "District Administrator")) {
              dispatch(loadUserFromToken({ user: { ...user, isSubperAdmin } }));
              dispatch(fetchDivisionData());
              dispatch(fetchUsersData({ ...user, isSubperAdmin }));
            } else {
              toast("error", "Invalid Email or Password");
            }
          }
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }
    dispatch(initializeSocket());
  }, [dispatch]);

  // Function to fetch user data using token
  const fetchUserData = async (token: string) => {
    try {
      // Ensure backend URL is available
      if (!process.env.REACT_APP_LOGIN_DEV_URL) {
        throw new Error('Backend URL not provided in environment variables');
      }
      const backendDevUrl = process.env.REACT_APP_LOGIN_DEV_URL;
      const response = await fetch(`${backendDevUrl}${fetchUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json(); // Try to parse error details
        // Handle specific error messages from the server (if applicable)
        if (errorData.error) {
          throw new Error(errorData.error.message); // Relay specific error message
        } else {
          throw new Error(`HTTP error ${response.status}: ${response.statusText}`);
        }
      }
      const userData = await response.json();
      if (loginType === 'Login as admin') {
        return userData.data;
      }
      return userData;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return Promise.reject(error); // Explicitly reject the promise
    }
  };

  return (
    <Provider store={store}>
      <Loading isLoading={false} />
      <Router>
        <Header />
        <div style={{ minHeight: '76vh' }}>
          <Banner imageUrl="/path/to/banner.jpg" />
          <Tabs />
          <Routes>
            <Route path="/" element={<Navigate to={`/users`} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/users" element={<PrivateRoute />}>
              <Route path="/users" element={<UserManagments />} />
            </Route>
            <Route path="/users" element={<PrivateRoute />}>
              <Route path="/users" element={<UserManagments />} />
            </Route>
            <Route path="/hierarchy" element={<PrivateRoute />}>
              <Route path="/hierarchy" element={<Hierarchy />} />
            </Route>
            <Route path="/events" element={<PrivateRoute />}>
              <Route path="/events" element={<Events />} />
            </Route>
            <Route path="/form" element={<PrivateRoute />}>
              <Route path="/form" element={<Forms />} />
            </Route>
            <Route path="/import" element={<PrivateRoute />}>
              <Route path="/import" element={<ImportComponent />} />
            </Route>
            <Route path="*" element={<h1 style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center'
            }}>Page Not found</h1>} />
          </Routes>
        </div>
        <Footer imageUrl="/path/to/banner.jpg" />
      </Router>
    </Provider>
  );
};

export default App;
