import React, { useEffect, useState } from 'react';
import PlusIcon from '../../assets/images/plusIcon.png';
import ReusableTable from '../../components/table';
import { useSelector } from 'react-redux';
import { RootState } from "../../reduxToolkit/store";
import SearchSelect from '../../components/selectDropdown';
import { Input } from 'antd';
import './index.css';
import Filters from '../../components/top_filter';
import { useNavigate } from 'react-router-dom';
import { userColumns } from '../../helpers/tableColumns';

const UserManagments: React.FC = () => {
    const { usersList } = useSelector((state: RootState) => state.users);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedTehsil, setSelectedTehsil] = useState('');
    const [selectedUnionCouncil, setSelectedUnionCouncil] = useState('');
    const [selectedParameter, setSelectedParameter] = useState('');
    const [selectedKeyword, setSelectedKeyword] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();

    const userData = alignUserData(usersList, selectedDivision, selectedDistrict, selectedTehsil, selectedUnionCouncil);

    return (
        <div className='Inventory-mainCont'>
            <Filters
                setSelectedDivision={setSelectedDivision}
                selectedDivision={selectedDivision}
                setSelectedDistrict={setSelectedDistrict}
                selectedDistrict={selectedDistrict}
                setSelectedTehsil={setSelectedTehsil}
                selectedTehsil={selectedTehsil}
                setSelectedUnionCouncil={setSelectedUnionCouncil}
                selectedUnionCouncil={selectedUnionCouncil}
            />
            <div className='ResourceMapping-table-cont'>
                <div className='ResourceMapping-table-filter-btn-cont'>
                    <div className='admin-add-data-btn' onClick={() => navigate("/form", { state: { formHeading: 'Users' } })}>
                        <img src={PlusIcon} alt="" />
                        <span>Add New Data</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap', width: "500px" }}>
                        <SearchSelect
                            onChange={env => { setSelectedParameter(env); setSelectedKeyword(''); }}
                            placeholder="Select the Parameter"
                            className="filter-input select-param-input"
                            value={selectedParameter || undefined}
                            options={userColumns.map(item => ({ value: item.dataIndex ?? '', label: item.title ?? '' }))}
                            allowClear={true}
                        />
                        <Input
                            placeholder="Keyword Search"
                            className=' filter-input select-param-input'
                            allowClear={true}
                            disabled={!selectedParameter}
                            onChange={({ target }) => setSelectedKeyword(target.value)}
                            value={selectedKeyword}
                        />
                        <div className='d-flex justify-content-between align-items-center select-param-input show-entries'>
                            <span>Show:</span>
                            <SearchSelect
                                onChange={(value: String) => setRowsPerPage(+value)}
                                placeholder="Select the District"
                                className="w-100px"
                                value={rowsPerPage}
                                options={[
                                    { value: "2", label: "2 Entries" },
                                    { value: "5", label: "5 Entries" },
                                    { value: "8", label: "8 Entries" },
                                    { value: "10", label: "10 Entries" },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div id="BottomTabs">
                    <ReusableTable
                        data={(selectedParameter && selectedKeyword) ? userData.filter((item: any) => {
                            const value = typeof item[selectedParameter] === 'string' ? item[selectedParameter].toLowerCase() : item[selectedParameter]?.toString();
                            return value && value.includes(selectedKeyword.toLowerCase());
                        }) : userData}
                        columns={userColumns}
                        rowsPerPage={rowsPerPage} />
                </div>
            </div>
        </div>
    );
};

export default UserManagments;

const alignUserData = (data: any, selectedDivision: String, selectedDistrict: String, selectedTehsil: String, selectedUnionCouncil: String) => {
    const alignedUserData = data?.filter((item: any) =>
        (selectedDivision ? item?.attributes?.division?.data?.attributes?.division_name === selectedDivision : true) &&
        (selectedDistrict ? item?.attributes?.district?.data?.attributes?.district_name === selectedDistrict : true) &&
        (selectedTehsil ? item?.attributes?.tehsil?.data?.attributes?.tehsil_name === selectedTehsil : true) &&
        (selectedUnionCouncil ? item?.attributes?.union_council?.data?.attributes?.union_council_name === selectedUnionCouncil : true)
    ).map((item: any, index: any) => ({
        key: index + 1,
        id: item.id,
        full_name: item?.attributes?.full_name,
        username: item?.attributes?.username,
        email: item?.attributes?.email,
        role: item?.attributes?.role?.data?.attributes?.name,
        mobile_number: item?.attributes?.mobile_number,
        user_type: item?.attributes?.user_type?.data?.attributes?.type,
        department: item?.attributes?.department?.data?.attributes?.name,
        provider: item?.attributes?.provider,
        address: item?.attributes?.address,
        division: item?.attributes?.division?.data?.attributes?.division_name,
        district: item?.attributes?.district?.data?.attributes?.district_name,
        tehsil: item?.attributes?.tehsil?.data?.attributes?.tehsil_name,
        union_council: item?.attributes?.union_council?.data?.attributes?.union_council_name,
        type: 'Users'
    })) || [];
    return alignedUserData;
};

