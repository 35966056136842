import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "../components/Toast";
import API from '../graphql';
import client from "../helpers";
import { RootState } from "./store";

const REACT_APP_ADMIN_TOKEN = process.env.REACT_APP_ADMIN_TOKEN;

interface Name {
    name: string; // Define the structure of your Name object
}

interface EntitiesState {
    [key: string]: any;
    divisionList: any[];
    districtList: Name[];
    allDistrictList: any[];
    tehsilList: Name[];
    alltehsilList: any[];
    unionCouncilList: Name[];
    allUnionCouncilList: any[];
    areaList: Name[];
    departmentsList: Name[];
    entitiesLoading: Boolean;
    eventList: any[];
    primaryAreasList: any[];
    secondaryAreasList: any[];
    productTypeList: any[];
    productNameList: any[];
    productCategorieList: any[];
    classList: any[],
    humanCasualtieType: any[];
    infrastructureTypeList: any[];
    agricultureTypeList: any[];
    livestockTypeList: any[];
}

const initialState: EntitiesState = {
    divisionList: [],
    districtList: [],
    tehsilList: [],
    unionCouncilList: [],
    areaList: [],
    allDistrictList: [],
    alltehsilList: [],
    allUnionCouncilList: [],
    departmentsList: [],
    entitiesLoading: false,
    eventList: [],
    primaryAreasList: [],
    secondaryAreasList: [],
    productTypeList: [],
    productNameList: [],
    productCategorieList: [],
    classList: [],
    humanCasualtieType: [],
    infrastructureTypeList: [],
    agricultureTypeList: [],
    livestockTypeList: [],
};


export const fetchDivisionData = createAsyncThunk('entities/fetchDivisionData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiDivision });
        return response.data.divisions.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchDistrictData = createAsyncThunk('entities/fetchDistrictData', async (division_name: string, { rejectWithValue }) => {
    try {
        if (!division_name) {
            return [];
        }
        const response = await client.query({
            query: API.ApiDistrict,
            variables: {
                "filter": {
                    "division": {
                        "division_name": {
                            "in": division_name
                        }
                    }
                }
            }
        });
        return response.data.districts.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchTehsilsData = createAsyncThunk('entities/fetchTehsilsData', async (district_name: string, { rejectWithValue }) => {
    try {
        if (!district_name) {
            return [];
        }
        const response = await client.query({
            query: API.ApiTehsils,
            variables: {
                "filter": {
                    "district": {
                        "district_name": {
                            "in": district_name
                        }
                    }
                }
            }
        });
        return response.data.tehsils.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchUnionCouncilData = createAsyncThunk('entities/fetchUnionCouncilData', async (tehsil_name: string, { rejectWithValue }) => {
    try {
        if (!tehsil_name) {
            return [];
        }
        const response = await client.query({
            query: API.ApiUnionCouncils,
            variables: {
                "filter": {
                    "tehsil": {
                        "tehsil_name": {
                            "in": tehsil_name
                        }
                    }
                }
            }
        });
        return response.data.unionCouncils.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllDistrictData = createAsyncThunk('entities/fetchAllDistrictData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllDistrict });
        return response.data.districts.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllTehsilData = createAsyncThunk('entities/fetchAllTehsilData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllTehsils });
        return response.data.tehsils.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllUnionCouncilData = createAsyncThunk('entities/fetchAllUnionCouncilData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllUnionCouncils });
        return response.data.unionCouncils.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchDepartmentsData = createAsyncThunk('entities/fetchDepartmentsData', async (user_type: string, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const loginType = localStorage.getItem('loginType');
        const token = loginType === 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        let variables: any = {
            "filter": {}
        };

        if (user_type) {
            variables.filter.user_type = {
                type: {
                    eq: user_type
                }
            };
        } else {
            variables = {
                filter: null // Setting filter to null fetches all departments
            };
        }
        const response = await client.query({
            query: API.ApiDepartments,
            variables,
            context: {
                headers,
            },
        });
        return response.data.departments.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllEvents = createAsyncThunk('entities/fetchAllEvents', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({
            query: API.ApiEvents,
            fetchPolicy: 'no-cache',
        });
        return response.data.events.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchProductTypesData = createAsyncThunk('inventory/fetchProductTypesData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiProductTypes, fetchPolicy: 'no-cache' });
        return response.data.productTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchProductNamesData = createAsyncThunk('inventory/fetchProductNamesData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiProductNames, fetchPolicy: 'no-cache' });
        return response.data.productNames.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchProductCategoriesData = createAsyncThunk('inventory/fetchProductCategoriesData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiProductCategories, fetchPolicy: 'no-cache' });
        return response.data.productCategories.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchClassesData = createAsyncThunk('inventory/fetchClassesData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetAllClasses, fetchPolicy: 'no-cache' });
        return response.data.classes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createDivisionData = createAsyncThunk('entities/createDivisionData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const loginType = localStorage.getItem('loginType');
        const token = loginType === 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateDivision,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createDivision.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createDistrictData = createAsyncThunk('entities/createDistrictData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const loginType = localStorage.getItem('loginType');
        const token = loginType === 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateDistrict,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createDistrict.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createTehsilData = createAsyncThunk('entities/createTehsilData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const loginType = localStorage.getItem('loginType');
        const token = loginType === 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateTehsil,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createTehsil.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createUnionCouncilData = createAsyncThunk('entities/createUnionCouncilData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const loginType = localStorage.getItem('loginType');
        const token = loginType === 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateUnionCouncil,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createUnionCouncil.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const createEventData = createAsyncThunk('entities/createEventData', async (data: any, { rejectWithValue, getState }) => {
    try {
        const authToken = (getState() as RootState).auth.token;
        const loginType = localStorage.getItem('loginType');
        const token = loginType === 'Login as admin' ? REACT_APP_ADMIN_TOKEN : authToken;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const response = await client.mutate({
            mutation: API.ApiCreateEvent,
            variables: data,
            context: {
                headers,
            },
        });
        return response.data.createEvent.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateDivisionRecordFromSocket = createAsyncThunk('entities/addAndUpdateDivisionRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetDivisionById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.division.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateDistrictRecordFromSocket = createAsyncThunk('entities/addAndUpdateDistrictRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetDistrictById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.district.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateTehsilRecordFromSocket = createAsyncThunk('entities/addAndUpdateTehsilRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetTehsilById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.tehsil.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateUnionCouncilRecordFromSocket = createAsyncThunk('entities/addAndUpdateUnionCouncilRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetUnionCouncilById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.unionCouncil.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateEventRecordFromSocket = createAsyncThunk('entities/addAndUpdateEventRecordFromSocket', async (data: any, { rejectWithValue }) => {
    try {
        const { recordId, userData } = data;
        const response = await client.query({ query: API.ApiGetEventById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return { data: response.data.event.data, userData };
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const deleteEntitiesRecordFromSocket = createAsyncThunk('entities/deleteDivisionRecordFromSocket', async (data: any, { rejectWithValue }) => {
    try {
        return data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateSecondaryAreaRecordFromSocket = createAsyncThunk('entities/addAndUpdateSecondaryAreaRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllSecondaryAreaById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.secondaryArea.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdatePrimaryAreaRecordFromSocket = createAsyncThunk('entities/addAndUpdatePrimaryAreaRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllPrimaryAreaById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.primaryArea.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllPrimaryAreas = createAsyncThunk('entities/fetchAllPrimaryAreas', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllPrimaryAreas, fetchPolicy: 'no-cache' });
        return response.data.primaryAreas.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllSecondaryAreas = createAsyncThunk('entities/fetchAllSecondaryAreas', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAllSecondaryAreas, fetchPolicy: 'no-cache' });
        return response.data.secondaryAreas.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateProductTypeRecordFromSocket = createAsyncThunk('entities/addAndUpdateProductTypeRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetProductTypeById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.productType.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateProductNameRecordFromSocket = createAsyncThunk('entities/addAndUpdateProductNameRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetProductNameById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.productName.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateProductCategoryRecordFromSocket = createAsyncThunk('entities/addAndUpdateProductCategoryRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetProductCategoryById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.productCategory.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateHumanCasualtieTypeRecordFromSocket = createAsyncThunk('entities/addAndUpdateHumanCasualtieTypeRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetCasualtyTypeById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.casualtyType.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchHumanCasualtieTypeData = createAsyncThunk('entities/fetchHumanCasualtieTypeData', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiCasualtyTypes, fetchPolicy: 'no-cache' });
        return response.data.casualtyTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateInfrastructureTypeRecordFromSocket = createAsyncThunk('entities/addAndUpdateInfrastructureTypeRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetInfrastructureTypeById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.infrastructureType.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllInfrastructureTypes = createAsyncThunk('entities/fetchAllInfrastructureTypes', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiInfrastructureTypes, fetchPolicy: 'no-cache' });
        return response.data.infrastructureTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateAgricultureTypeRecordFromSocket = createAsyncThunk('entities/addAndUpdateAgricultureTypeRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetAgricultureTypeById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.agricultureType.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllAgricultureTypes = createAsyncThunk('entities/fetchAllAgricultureTypes', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiAgricultureTypes, fetchPolicy: 'no-cache' });
        return response.data.agricultureTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const addAndUpdateLivestockTypeRecordFromSocket = createAsyncThunk('livestockDamage/addAndUpdateLivestockTypeRecordFromSocket', async (recordId: any, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetLivestockTypeById, variables: { id: recordId }, fetchPolicy: 'no-cache' });
        return response.data.livestockType.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllLivestockTypes = createAsyncThunk('livestockDamage/fetchAllLivestockTypes', async (_, { rejectWithValue }) => {
    try {
        const response = await client.query({ query: API.ApiGetLivestockTypes, fetchPolicy: 'no-cache' });
        return response.data.livestockTypes.data;
    } catch (error: any) {
        toast('error', error.message);
        return rejectWithValue(error);
    }
});

const entitiesSlice = createSlice({
    name: 'entities',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchDivisionData.fulfilled, (state, action) => {
                state.divisionList = action.payload; // Since action.payload is already an array of Division
                state.entitiesLoading = false;
            })
            .addCase(fetchDistrictData.fulfilled, (state, action) => {
                state.districtList = action.payload; // Since action.payload is already an array of District
                state.entitiesLoading = false;
            })
            .addCase(fetchTehsilsData.fulfilled, (state, action) => {
                state.tehsilList = action.payload; // Since action.payload is already an array of District
                state.entitiesLoading = false;
            })
            .addCase(fetchUnionCouncilData.fulfilled, (state, action) => {
                state.unionCouncilList = action.payload; // Since action.payload is already an array of District
                state.entitiesLoading = false;
            })
            .addCase(fetchAllDistrictData.fulfilled, (state, action) => {
                state.allDistrictList = action.payload; // Since action.payload is already an array of District
                state.entitiesLoading = false;
            })
            .addCase(fetchAllTehsilData.fulfilled, (state, action) => {
                state.alltehsilList = action.payload; // Since action.payload is already an array of District
                state.entitiesLoading = false;
            })
            .addCase(fetchAllUnionCouncilData.fulfilled, (state, action) => {
                state.allUnionCouncilList = action.payload; // Since action.payload is already an array of District
                state.entitiesLoading = false;
            })
            .addCase(fetchDepartmentsData.fulfilled, (state, action) => {
                state.departmentsList = action.payload; // Since action.payload is already an array of District
                state.entitiesLoading = false;
            })
            .addCase(fetchAllEvents.fulfilled, (state, action) => {
                state.eventList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllPrimaryAreas.fulfilled, (state, action) => {
                state.primaryAreasList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllSecondaryAreas.fulfilled, (state, action) => {
                state.secondaryAreasList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchProductTypesData.fulfilled, (state, action) => {
                state.productTypeList = action.payload;
            })
            .addCase(fetchClassesData.fulfilled, (state, action) => {
                state.classList = action.payload;
            })
            .addCase(fetchProductNamesData.fulfilled, (state, action) => {
                state.productNameList = action.payload;
            })
            .addCase(fetchProductCategoriesData.fulfilled, (state, action) => {
                state.productCategorieList = action.payload;
            })
            .addCase(fetchHumanCasualtieTypeData.fulfilled, (state, action) => {
                state.humanCasualtieType = action.payload;
            })
            .addCase(fetchAllInfrastructureTypes.fulfilled, (state, action) => {
                state.infrastructureTypeList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllAgricultureTypes.fulfilled, (state, action) => {
                state.agricultureTypeList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(fetchAllLivestockTypes.fulfilled, (state, action) => {
                state.livestockTypeList = action.payload; // Since action.payload is already an array of District
            })
            .addCase(createDivisionData.fulfilled, (state, action) => {
                state.divisionList.push(action.payload);
                state.entitiesLoading = false;
            })
            .addCase(createDistrictData.fulfilled, (state, action) => {
                state.allDistrictList.push(action.payload);
                state.entitiesLoading = false;
            })
            .addCase(createTehsilData.fulfilled, (state, action) => {
                state.alltehsilList.push(action.payload);
                state.entitiesLoading = false;
            })
            .addCase(createUnionCouncilData.fulfilled, (state, action) => {
                state.allUnionCouncilList.push(action.payload);
                state.entitiesLoading = false;
            })
            .addCase(createEventData.fulfilled, (state, action) => {
                state.allUnionCouncilList.push(action.payload);
                state.entitiesLoading = false;
            })
            .addCase(addAndUpdateDivisionRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.divisionList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.divisionList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.divisionList = updatedArray;
            })
            .addCase(addAndUpdateDistrictRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.allDistrictList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.allDistrictList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.allDistrictList = updatedArray;
                const selectedDistrict = state.districtList.find((item: any) => item?.attributes?.division?.data?.id == attributes?.division?.data?.id);
                if (selectedDistrict) {
                    const selectedDistrictIndex = state.districtList.findIndex((item: any) => item.id == id);
                    selectedDistrictIndex !== -1 ? state.districtList[selectedDistrictIndex] = action.payload : state.districtList.push(action.payload);
                }
            })
            .addCase(addAndUpdateTehsilRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.alltehsilList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.alltehsilList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.alltehsilList = updatedArray;
                const selectedTehsil = state.tehsilList.find((item: any) => item?.attributes?.district?.data?.id == attributes?.district?.data?.id);
                if (selectedTehsil) {
                    const selectedTehsilIndex = state.tehsilList.findIndex((item: any) => item.id == id);
                    selectedTehsilIndex !== -1 ? state.tehsilList[selectedTehsilIndex] = action.payload : state.tehsilList.push(action.payload);
                }
            })
            .addCase(addAndUpdateUnionCouncilRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.allUnionCouncilList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.allUnionCouncilList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.allUnionCouncilList = updatedArray;
                const selectedUnionCouncil = state.unionCouncilList.find((item: any) => item?.attributes?.tehsil?.data?.id == attributes?.tehsil?.data?.id);
                if (selectedUnionCouncil) {
                    const selectedUnionCouncilIndex = state.unionCouncilList.findIndex((item: any) => item.id == id);
                    selectedUnionCouncilIndex !== -1 ? state.unionCouncilList[selectedUnionCouncilIndex] = action.payload : state.unionCouncilList.push(action.payload);
                }
            })
            .addCase(addAndUpdateEventRecordFromSocket.fulfilled, (state, action) => {
                const userData = action.payload.userData;
                const { id, attributes } = action.payload.data;
                const index = state.eventList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.eventList];
                index !== -1 ? updatedArray[index] = action.payload.data : updatedArray.push(action.payload.data);
                state.eventList = updatedArray;
            })
            .addCase(deleteEntitiesRecordFromSocket.fulfilled, (state, action) => {
                action.payload.list.forEach((listItem: string) => {
                    if (state.hasOwnProperty(listItem)) {
                        state[listItem] = [...state[listItem].filter((item: any) => item.id != action.payload.recordId)];
                    }
                });
            })
            .addCase(addAndUpdatePrimaryAreaRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.primaryAreasList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.primaryAreasList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.primaryAreasList = updatedArray;
            })
            .addCase(addAndUpdateSecondaryAreaRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.secondaryAreasList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.secondaryAreasList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.secondaryAreasList = updatedArray;
            })
            .addCase(addAndUpdateProductTypeRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.productTypeList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.productTypeList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.productTypeList = updatedArray;
            })
            .addCase(addAndUpdateProductNameRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.productNameList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.productNameList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.productNameList = updatedArray;
            })
            .addCase(addAndUpdateProductCategoryRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.productCategorieList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.productCategorieList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.productCategorieList = updatedArray;
            })
            .addCase(addAndUpdateHumanCasualtieTypeRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.humanCasualtieType.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.humanCasualtieType];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.humanCasualtieType = updatedArray;
            })
            .addCase(addAndUpdateInfrastructureTypeRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.infrastructureTypeList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.infrastructureTypeList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.infrastructureTypeList = updatedArray;
            })
            .addCase(addAndUpdateAgricultureTypeRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.agricultureTypeList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.agricultureTypeList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.agricultureTypeList = updatedArray;
            })
            .addCase(addAndUpdateLivestockTypeRecordFromSocket.fulfilled, (state, action) => {
                const { id, attributes } = action.payload;
                const index = state.livestockTypeList.findIndex((item: any) => item.id == id);
                const updatedArray = [...state.livestockTypeList];
                index !== -1 ? updatedArray[index] = action.payload : updatedArray.push(action.payload);
                state.livestockTypeList = updatedArray;
            });;
        [
            fetchDepartmentsData,
            fetchAllUnionCouncilData,
            fetchAllTehsilData,
            fetchAllDistrictData,
            fetchUnionCouncilData,
            createUnionCouncilData,
            fetchTehsilsData,
            createTehsilData,
            fetchDistrictData,
            createDistrictData,
            fetchDivisionData,
            createDivisionData,
            createEventData
        ].forEach(action => {
            builder
                .addCase(action.pending, (state) => {
                    state.entitiesLoading = true;
                })
                .addCase(action.rejected, (state) => {
                    state.entitiesLoading = false;
                });
        });
    }
});


export default entitiesSlice.reducer;
