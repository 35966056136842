import React, { useEffect, useState } from 'react';
import './index.css'; // Import CSS for styling
import { FaArrowLeft } from "react-icons/fa6";
import userManagementIcon from '../../assets/images/User Management (White).svg';
import { Button, Input, Space } from 'antd';
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxToolkit/store';
import { toast } from '../../components/Toast';
import { createUserData } from '../../reduxToolkit/userSlice';
import { fetchDistrictData, fetchTehsilsData, fetchUnionCouncilData } from '../../reduxToolkit/entitiesSlice';
import { emailValidate } from '../../helpers/common';

const UserForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const { districtList, divisionList, tehsilList, unionCouncilList, departmentsList } = useSelector((state: RootState) => state.entities);
    const { userData } = useSelector((state: RootState) => state.auth);
    const { rolesList, userTypeList } = useSelector((state: RootState) => state.users);
    const [newUser, setNewUser] = useState<{ [key: string]: any; }>({});
    const [isCheckRequired, setIsCheckRequired] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        if (!userData.isSubperAdmin) {
            const obj = {
                division: userData?.division?.division_name,
                divisionId: userData?.division?.id,
                district: userData?.district?.district_name,
                districtId: userData?.district?.id,
            };
            setNewUser(prevState => ({ ...prevState, ...obj }));
        }
    }, [userData]);

    const divisionData = divisionList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.division_name,
        label: item.attributes.division_name,
    })) || [];

    const districtData = districtList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.district_name,
        label: item.attributes.district_name,
    })) || [];

    const tehsilData = tehsilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.tehsil_name,
        label: item.attributes.tehsil_name,
    })) || [];

    const unionCouncilListData = unionCouncilList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.union_council_name,
        label: item.attributes.union_council_name,
    })) || [];

    const rolesListData = rolesList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    const userTypeListData = userTypeList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.type,
        label: item.attributes.type,
    })) || [];

    const departmentsListData = departmentsList.map((item: any, index) => ({
        id: item.id,
        value: item.attributes.name,
        label: item.attributes.name,
    })) || [];

    useEffect(() => {
        dispatch(fetchDistrictData(newUser?.division));
    }, [dispatch, newUser?.division]);

    useEffect(() => {
        dispatch(fetchTehsilsData(newUser?.district));
    }, [dispatch, newUser?.district]);

    useEffect(() => {
        dispatch(fetchUnionCouncilData(newUser?.tehsil));
    }, [dispatch, newUser?.tehsil]);

    const filterOption = (input: string, option?: { label: string; value: string; }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onSave = async () => {
        try {
            if (isCreating) return;
            setIsCreating(true);
            const obj: any = {
                "full_name": newUser.full_name,
                "username": newUser.username,
                "email": newUser.email,
                "mobile_number": newUser.mobile_number,
                "password": newUser.password,
                "address": newUser.address,
                "department": newUser.departmentId,
                "role": newUser.roleId,
                "user_type": newUser.user_typeId,
                "division": newUser.divisionId,
                "district": newUser.districtId,
                "confirmed": true,
            };
            if (Object.values(obj).some(value => !value)) {
                setIsCheckRequired(true);
                throw new Error('Please fill the all highlighted fields');
            }
            obj.tehsil = newUser.tehsilId;
            obj.union_council = newUser.union_councilId;
            obj.blocked = false;
            const res = await dispatch(createUserData(obj));
            if (res.type !== "users/createUserData/rejected") {
                navigate(-1);
                toast('success', "User create successfully");
            } else {
                setIsCreating(false);
            }
        } catch (error: any) {
            setIsCreating(false);
            toast('error', error.message);
        }
    };

    function generateUsername(role: any, district: String) {
        if (role && district) {
            const username = district.split(' ').map(word => word[0]).join('').toUpperCase() + '.' + role.match(/\b\w/g).join('').toUpperCase();
            setNewUser(prevState => ({ ...prevState, username: username }));
        }
    }

    const generatePassword = () => {
        const password = Array.from(crypto.getRandomValues(new Uint8Array(8)))
            .map(x => String.fromCharCode(x % 94 + 33))
            .join('');
        setNewUser(prevState => ({ ...prevState, password: password }));
    };


    return (
        <div className="form-main-cont" >
            <div className='form-head-cont'>
                <div className='form-head'>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft style={{ width: "26.79px", height: "23px", color: "#FFFFFF" }} />
                        <span style={{ fontSize: "14px" }}>Back</span>
                    </div>
                    <div>
                        <div>
                            <img src={userManagementIcon} alt="" style={{ width: "40px", height: "40px" }} />
                            <span>User Management</span>
                        </div>
                    </div>
                    <div className='form-send-btn' onClick={onSave}>
                        <span className='sand-btn-text'>Save Information</span>
                    </div>
                </div>
            </div>
            <div className='form-body'>
                <div className='form-cont'>
                    <div className='input-cont'>
                        <span>Full Name</span>
                        <Input
                            placeholder="Enter Full Name"
                            onChange={({ target }) => setNewUser(prevState => ({ ...prevState, full_name: target.value }))}
                            value={newUser.full_name}
                            status={(isCheckRequired && !newUser.full_name) ? 'error' : ""}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Email</span>
                        <Input
                            placeholder="Enter Email"
                            status={(isCheckRequired && !newUser.email) ? "error" : newUser.email ? emailValidate(newUser.email) ? "" : "error" : ""}
                            onChange={({ target }) => setNewUser(prevState => ({ ...prevState, email: target.value }))}
                            value={newUser.email}
                            type='email'
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>User Name</span>
                        <Input
                            placeholder="Enter User Name"
                            disabled
                            // onChange={({ target }) => setNewUser(prevState => ({ ...prevState, username: target.value }))}
                            value={newUser.username}
                            className='input' />
                    </div>
                    <div className='input-cont Password'>
                        <span>Password</span>
                        <Space.Compact style={{ width: '100%' }}>
                            <Input
                                disabled
                                status={(isCheckRequired && !newUser.password) ? 'error' : ""}
                                placeholder="Enter Password"
                                // onChange={({ target }) => setNewUser(prevState => ({ ...prevState, password: target.value }))}
                                value={newUser.password}
                                className={`input ${isCheckRequired && !newUser.password ? 'custom-error' : ''}`} />
                            <Button className='input' onClick={generatePassword}>Generate</Button>
                        </Space.Compact>
                    </div>
                    <div className='input-cont'>
                        <span>Mobile Number</span>
                        <Input
                            placeholder="Enter Mobile Number"
                            status={(isCheckRequired && !newUser.mobile_number) ? 'error' : ""}
                            onChange={({ target }) => setNewUser(prevState => ({ ...prevState, mobile_number: target.value }))}
                            value={newUser.mobile_number}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Role</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select Role"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setNewUser(prevState => ({ ...prevState, roleId: item?.id, role: item?.value }))}
                            onBlur={() => generateUsername(newUser.role, newUser.district)}
                            options={rolesListData}
                            status={(isCheckRequired && !newUser.roleId) ? 'error' : ""}
                            className='input'
                            value={newUser.role || undefined}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>User Type</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select User Type"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setNewUser(prevState => ({ ...prevState, user_typeId: item?.id, user_type: item?.value }))}
                            options={userTypeListData}
                            className='input'
                            status={(isCheckRequired && !newUser.user_typeId) ? 'error' : ""}
                            value={newUser.user_type || undefined}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Department</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select Role"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setNewUser(prevState => ({ ...prevState, departmentId: item?.id, department: item?.value }))}
                            options={departmentsListData}
                            className='input'
                            status={(isCheckRequired && !newUser.departmentId) ? 'error' : ""}
                            value={newUser.department || undefined}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Address</span>
                        <Input
                            placeholder="Enter Address"
                            onChange={({ target }) => setNewUser(prevState => ({ ...prevState, address: target.value }))}
                            status={(isCheckRequired && !newUser.address) ? 'error' : ""}
                            value={newUser.address}
                            className='input' />
                    </div>
                    <div className='input-cont'>
                        <span>Division</span>
                        <Select
                            value={newUser.division || undefined}
                            placeholder="Select the Division"
                            disabled={!userData.isSubperAdmin}
                            status={(isCheckRequired && !newUser.divisionId) ? 'error' : ""}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setNewUser(prevState => ({ ...prevState, divisionId: item?.id, division: item?.value }))}
                            options={divisionData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>District</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the District"
                            disabled={!userData.isSubperAdmin}
                            value={newUser.district || undefined}
                            onBlur={() => generateUsername(newUser.role, newUser.district)}
                            status={(isCheckRequired && !newUser.districtId) ? 'error' : ""}
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setNewUser(prevState => ({ ...prevState, districtId: item?.id, district: item?.value }))}
                            options={districtData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Tehsil</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Tehsil"
                            style={{ width: '100%' }}
                            value={newUser.tehsil || undefined}
                            onChange={(value: any, item: any) => setNewUser(prevState => ({ ...prevState, tehsilId: item?.id, tehsil: item?.value }))}
                            options={tehsilData}
                            className='input'
                            allowClear
                        />
                    </div>
                    <div className='input-cont'>
                        <span>Union Council</span>
                        <Select
                            // defaultValue="a1"
                            placeholder="Select the Union Council"
                            style={{ width: '100%' }}
                            onChange={(value: any, item: any) => setNewUser(prevState => ({ ...prevState, union_councilId: item?.id, union_council: item?.value }))}
                            options={unionCouncilListData}
                            className='input'
                            value={newUser.union_council || undefined}
                            allowClear
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default UserForm;
