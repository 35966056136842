// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner img {
  /* transform: translateX(100%); */
  width: 26%;
  transition: transform 0.5s ease-out;
  animation-name: flip;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  backface-visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,2CAA2C;EAC3C,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;EACjC,UAAU;EACV,mCAAmC;EACnC,oBAAoB;EACpB,wBAAwB;EACxB,6BAA6B;EAC7B,mCAAmC;EACnC,mCAAmC;EACnC,2BAA2B;AAC7B","sourcesContent":[".spinner {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(255, 255, 255, 0.85);\n  z-index: 9999999;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.spinner img {\n  /* transform: translateX(100%); */\n  width: 26%;\n  transition: transform 0.5s ease-out;\n  animation-name: flip;\n  animation-duration: 1.5s;\n  animation-fill-mode: forwards;\n  animation-timing-function: ease-out;\n  animation-iteration-count: infinite;\n  backface-visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
